import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firestore';

export default {
  async login(context, payload) {
    await firebase
      .auth()
      .signInWithEmailAndPassword(payload.email, payload.password);
  },
  async PasswordReset(context, payload) {
    await firebase.auth().sendPasswordResetEmail(payload.email);
  },
  async signup(context, payload) {
    const addNewUser = firebase.functions().httpsCallable('addNewUser');
    return await addNewUser(payload);
  },
  logout() {
    firebase
      .auth()
      .signOut()
      .then(() => {});
  },
  async getUserList(context) {
    const data = [];
    await firebase
      .firestore()
      .collection('users')
      .get()
      .then(userList => {
        userList.forEach(user => {
          const payload = {
            email: user.data().email,
            role: user.data().role,
            uid: user.data().uid,
            dbID: user.id
          };
          data.push(payload);
        });
        context.commit('setUserList', data);
      });
  },
  async deleteUser(_, payload) {
    const deleteUser = firebase.functions().httpsCallable('deleteUser');
    return await deleteUser(payload);
  }
};

//   async PasswordResetConfirmation(context, payload) {
//     const emailconfirmation = await firebase.auth().verifyPasswordResetCode(payload.resetCode)
//     if (emailconfirmation === payload.email){
//     await firebase.auth().confirmPasswordReset();
//   }
//     else throw new Error ({message: 'El codigo no coincide con el enviado'})

// },
