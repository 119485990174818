export default {
  getIsLayerLoading(state) {
    return state.isLayerLoading;
  },
  getTheMapObject(state) {
    return state.theMap;
  },
  getMapFormsTypes(state) {
    return state.mapFormsTypes;
  },
  getFormType(state) {
    return state.mapFormsTypes[state.mapFormCurrentType];
  },
  getFormCurrentType(state) {
    return state.mapFormCurrentType;
  },
  getMapDetails(state) {
    return state.mapDetails;
  },
  getMapDetailsIdList(state) {
    return state.mapDetailsIdList;
  },
  getFormDetails(state) {
    return state.mapFormDetails[state.mapFormsTypes[state.mapFormCurrentType]];
  },
  getMapDetailsRiskFactor(state) {
    if (state.mapDetailsRiskFactor != null) {
      let orderedTitles = {};
      state.mapForms[state.mapFormCurrentType].data.forEach(titleArray => {
        for (let title in titleArray) {
          orderedTitles[title] = state.mapDetailsRiskFactor[title];
        }
      });
      orderedTitles['INDICE RIESGO TOTAL'] =
        state.mapDetailsRiskFactor['INDICE RIESGO TOTAL'];
      return orderedTitles;
    } else return state.mapDetailsRiskFactor;
  },
  getMapDetailsRiskFactorTemplate(state) {
    if (
      state.mapDetailsRiskFactorTemplate != null &&
      state.mapForms[state.mapFormCurrentType].data != undefined
    ) {
      let orderedTitles = {};
      state.mapForms[state.mapFormCurrentType].data.forEach(titleArray => {
        for (let title in titleArray) {
          orderedTitles[title] = state.mapDetailsRiskFactorTemplate[title];
        }
      });
      orderedTitles['INDICE RIESGO TOTAL'] =
        state.mapDetailsRiskFactorTemplate['INDICE RIESGO TOTAL'];
      return orderedTitles;
    } else return state.mapDetailsRiskFactorTemplate;
  },
  getRiskFactorList(state) {
    return state.RiskFactorList;
  },
  getRiskFactorListPlusExtraData(state) {
    let mergedList = [];
    let riskList = state.RiskFactorList;
    let extraList = state.RiskFactorExtraDataList;
    riskList.forEach((item, index) => {
      const merged = {
        ...item,
        ...extraList[index]
      };
      mergedList.push(merged);
    });
    return mergedList;
  },
  getRiskFactorListIds(state) {
    return state.RiskFactorListIds;
  },
  getFormBody(state) {
    return state.mapForms[state.mapFormCurrentType].data;
  },
  getTutorialStepper(state) {
    return state.tutorialStepper;
  },
  getFormTooltipData(state) {
    return state.formTooltipData[state.mapFormsTypes[state.mapFormCurrentType]];
  },
  getHistoricFilesList(state) {
    return state.historicFilesList;
  },
  getTooltipImgUrls(state) {
    return state.tooltipImgUrlsList;
  }
};
