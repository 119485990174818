<template>
  <teleport to="body">
    <div v-if="show" @click="tryClose" class="backdrop"></div>
    <dialog open v-if="show">
      <header>
        <slot name="header">
          <img
            class="grid-item-btn"
            :src="xIcon"
            alt="Close"
            @click="tryClose"
          />
          <h3>{{ title }}</h3>
        </slot>
      </header>
      <section>
        <slot></slot>
      </section>
      <menu v-if="!fixed">
        <slot name="actions"> </slot>
      </menu>
    </dialog>
  </teleport>
</template>

<script>
export default {
  data() {
    return {
      xIcon: require('../../assets/img/x.svg')
    };
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['close'],
  methods: {
    tryClose() {
      if (this.fixed) {
        return;
      }
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 101;
}

dialog {
  position: fixed;
  top: 5vh;
  left: 10%;
  width: 100%;
  z-index: 102;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: white;
  min-height: 75%;
  max-height: 90%;
}

header {
  background-color: #006bca;
  color: white;
  width: 100%;
  padding: 1rem 1rem 1rem 2.5rem;
  overflow: hidden;
}

header h3 {
  margin: 0;
}
header img {
  float: right;
  width: 25px;
  height: 25px;
  filter: invert(1);
}

header img:hover {
  background-color: rgba(255, 255, 255, 0.26);
  border-radius: 5px;
}

menu {
  padding: 0.5rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  overflow: hidden;
}

@media (min-width: 820px) {
  dialog {
    left: calc(50% - 25rem);
    width: 50rem;
  }
}
.btn {
  position: relative;
  right: 0px;
  margin: 0;
  bottom: 10px;
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}
</style>
