<template>
  <div>
    <div class="backgroundCard" v-if="!tutorialOn">
      <img
        :src="logoValparaiso"
        alt="Logout"
        width="203"
        height="124"
        id="logo"
      />
      <h2>PORTAL GESTIÓN DEL RIESGO DE DESASTRE</h2>
      <h3>Sitio Patrimonio Mundial de Valparaíso</h3>
      <p>
        El <strong>Portal de Gestión de Riesgo de Desastres</strong> permite
        evaluar la vulnerabilidad de los inmuebles insertos en el Sitio
        Patrimonio Mundial de Valparaíso ante amenazas de<strong>
          incendio, sismo, deslizamiento de tierra y grafiti vandálico</strong
        >. La evaluación de los inmuebles se lleva a cabo a través de un
        formulario interactivo que rescata información esencial para cada
        amenaza. Los resultados se visualizan en un<strong> mapa SIG</strong>.
      </p>
      <base-button v-on:click="tutorialOn = !tutorialOn">
        Comenzar tutorial
      </base-button>
      <router-link to="/map" tag="button" class="map-button"
        ><base-button>Ir al mapa</base-button></router-link
      >
      <dowload-button class="downloadButton" />
    </div>
    <div class="backgroundCardTutorial" v-if="tutorialOn == true">
      <tutorial-body v-bind:titles="titles" @close="closeTutorial" />
    </div>
  </div>
</template>

<script>
import dowloadButton from '../components/layout/downloadPDF.vue';
import BaseButton from '../components/UI/BaseButton.vue';
export default {
  components: {
    dowloadButton,
    BaseButton
  },
  data() {
    return {
      tutorialOn: false,
      logoValparaiso: require('../assets/img/version-colores-corporativos-caluga-municipalidad.svg'),
      titles: [
        'VISUALIZACIÓN',
        'VISUALIZACIÓN',
        'EVALUACIÓN POR INMUEBLE',
        'EVALUACIÓN POR INMUEBLE'
      ]
    };
  },
  mounted() {
    this.$store.commit('setCurrentPage', 'home');
    this.$store.dispatch('loadMapForms');
    this.$store.dispatch('loadFormsDetails');
  },
  computed: {
    currentStepper() {
      return this.titles[this.$store.getters['getTutorialStepper']];
    }
  },
  methods: {
    closeTutorial() {
      this.$store.commit('setTutorialStepper', 0);
      this.tutorialOn = false;
    },
    changeTitle(stepper) {
      this.currentTitle = stepper;
      console.log(stepper);
    },
    downloadFile(dir) {
      this.$store.dispatch('OpenPdfFile', dir);
    }
  }
};
</script>

<style scoped>
.backgroundCard {
  color: black;
  background-color: rgba(255, 255, 255, 0.85);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 3rem 3rem 3rem 3rem;
  margin: 4rem auto;
  max-width: 50rem;
  vertical-align: middle;
  top: 20px;
}
.backgroundCard p {
  line-height: 150%;
  margin: 2rem 0 2rem 0;
}
.backgroundCard h3 {
  padding: 0;
  margin: 0;
}
p {
  text-align: justify;
  font-size: 15px;
}
.map-button {
  padding: 10px;
}
.table-button {
  padding: 10px;
}
.downloadButton {
  display: inline-block;
  padding-bottom: 30px;
}
@media only screen and (max-width: 1200px) {
  .backgroundCard {
    max-width: 60rem;
  }
  .bodytext {
    max-width: 55rem;
  }
}
@media only screen and (max-width: 960px) {
  .backgroundCard {
    max-width: 45rem;
  }
  .bodytext {
    max-width: 40rem;
  }
}
h2 {
  font-size: 22px;
}
</style>
