<template>
  <div>
    <h4 id="disclaimer">
      Proyecto desarrollado con financiamiento del Programa Social Sitios de
      Patrimonio Mundial del Servicio Nacional del Patrimonio Cultural.
    </h4>
    <base-dialog :show="!!error" title="Ocurrio un Error" @close="handleError">
      <p>{{ error }}</p>
    </base-dialog>
    <base-backdrop :show="isLoading" title="Autentificando..." fixed>
      <base-spinner></base-spinner>
    </base-backdrop>
    <div>
      <div class="form" v-if="loginPage">
        <div id="diamond-wrapper"><div id="diamond"></div></div>
        <div class="card-look">
          <h2 class="title1">Portal</h2>
          <h2 class="title2">Usuario</h2>
          <h2 class="title3">Inicio de sesión</h2>
          <form @submit.prevent="submitLogin">
            <div class="form-control">
              <input
                type="email"
                id="email"
                placeholder="Correo Electrónico"
                v-model.trim="email"
              />
            </div>
            <div class="form-control">
              <input
                type="password"
                id="password"
                placeholder="Contraseña"
                v-model.trim="password"
              />
            </div>
            <div><base-button class="login-button">Ingresar</base-button></div>
          </form>
          <button @click="switchForm" class="astext">
            <i>Recuperar Contraseña</i>
          </button>
        </div>
      </div>
      <div class="card-look-recovery" v-else>
        <form @submit.prevent="recoverPassword">
          <img
            :src="image"
            alt="Atras"
            width="30"
            height="30"
            @click="switchForm"
          />
          <h1>Recuperar cuenta</h1>
          <h4>
            Ingresa tu correo electrónico para recuperar tu contraseña,
            recibirás un correo en tu mail con los pasos a seguir.
          </h4>
          <input
            type="emailRecovery"
            id="emailRecovery"
            placeholder="Correo Electrónico"
            v-model.trim="emailRecovery"
          />
          <base-button class="recovery-button">Recuperar</base-button>
        </form>
      </div>
    </div>
    <p className="merkenText">
      Powered by Merken Labs. &nbsp;
      <img :src="pepper" width="22" height="22" />
    </p>
  </div>
</template>

<script>
import router from '../router.js';

export default {
  data() {
    return {
      email: '',
      emailRecovery: '',
      password: '',
      formPassIsValid: true,
      isLoading: false,
      error: null,
      loginPage: true,
      image: require('../assets/img/BackArrow.png'),
      pepper: require('../assets/img/pepperRojo.png')
    };
  },
  mounted() {
    this.$store.commit('setCurrentPage', 'login');
  },
  computed: {},
  methods: {
    async submitLogin() {
      this.isLoading = true;
      try {
        await this.$store.dispatch('login', {
          email: this.email,
          password: this.password
        });
      } catch (err) {
        if (err.code == 'auth/user-not-found')
          this.error = 'El usuario que intento ingresar no existe';
        else if (err.code == 'auth/wrong-password')
          this.error = 'La contraseña ingresada no corresponde con el usuario';
        else if (err.code == 'auth/invalid-email')
          this.error = 'El usuario que ingreso es inválido';
        else
          this.error =
            'Ocurrio un error, por favor intente ingresar su usuario nuevamente';
      }
      this.isLoading = false;
    },

    async recoverPassword() {
      try {
        await this.$store.dispatch('PasswordReset', {
          email: this.emailRecovery
        });
        router.push('/');
      } catch (err) {
        if (err.code == 'auth/user-not-found')
          this.error = 'El usuario que intento ingresar no existe';
        else if (err.code == 'auth/invalid-email')
          this.error = 'El usuario que ingreso es inválido';
        else
          this.error =
            'Ocurrio un error, por favor intente ingresar su usuario nuevamente';
      }
      this.isLoading = false;
    },
    switchForm() {
      this.loginPage = !this.loginPage;
    },
    handleError() {
      this.error = null;
    }
  }
};
</script>

<style scoped>
form {
  margin: 1rem 1rem 0 1rem;
  border-radius: 8px;
  padding: 1rem 1rem 0 0;
  height: 210px;
}

div.form {
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}

input,
textarea {
  display: block;
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  padding: 0.15rem;
}

input:focus,
textarea:focus {
  border-color: #006bca;
  background-color: #faf6ff;
  outline: none;
}

.card-look {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 6rem 0rem 0rem 12rem;
  margin: 2rem auto;
  max-width: 40rem;
  vertical-align: middle;
  margin: 0;
}
.card-look h2 {
  position: relative;
  color: white;
  padding: 0;
  margin: 0;
}
.card-look .title1 {
  right: 335px;
  bottom: 90px;
}
.card-look .title2 {
  right: 295px;
  bottom: 85px;
}
.card-look .title3 {
  right: 20px;
  bottom: 40px;
  font-size: 30px;
  color: #ff5c00;
}

.card-look input {
  position: relative;
  border-radius: 8px;
  padding: 1rem 1rem 1rem 1rem;
  width: 330px;
  right: 20px;
  bottom: 40px;
  margin: 0;
}

.card-look .login-button {
  position: relative;
  left: 80px;
  font-size: 18px;
  margin: 0;
}

.astext {
  position: relative;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: #ff5c00;
  left: 70px;
  bottom: 110px;
}

#diamond {
  border: 75px solid transparent;
  border-bottom-color: #ff5c00;
  position: relative;
  top: -75px;
}
#diamond:after {
  content: '';
  position: absolute;
  left: -75px;
  top: 75px;
  width: 0;
  height: 0;
  border: 75px solid transparent;
  border-top-color: #006bca;
}
#diamond-wrapper {
  position: relative;
  right: 75.5px;
  top: 150px;
}

h1 {
  color: #ff5c00;
}

.card-look-recovery {
  display: grid;
  grid-gap: 50px 100px;
  position: absolute;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.35);
  padding: 0 0rem 7rem 0rem;
  margin: 2rem auto;
  width: 570px;
  vertical-align: middle;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card-look-recovery form {
  display: grid;
}
.card-look-recovery h1 {
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: 4;
}
.card-look-recovery img {
  position: absolute;
  grid-row: 1;
  grid-column: 1;
  left: 70px;
  top: 60px;
}
.card-look-recovery h4 {
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: 4;
  align-content: center;
}

.card-look-recovery input {
  grid-row: 3;
  grid-column-start: 1;
  grid-column-end: 3;
  border-radius: 8px;
  margin: 20px 0 0 30px;
  width: 180%;
  padding: 1rem 1rem 1rem 1rem;
}

.recovery-button {
  grid-row: 3;
  grid-column-start: 3;
  grid-column-end: 4;
  width: 40%;
  margin: 20px 0 0 180px;
  font-size: 16px;
}

#disclaimer {
  position: fixed;
  color: white;
  width: 500px;
  text-align: center;
  right: 50%;
  bottom: 30px;
  transform: translate(50%, 50%);
}

.merkenText {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: white;
}
.merkenText img {
  position: relative;
  top: 4px;
}
</style>
