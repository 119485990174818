<template>
  <div>
    <div class="titleCard">
      <h3>Índices de riesgo por {{ getMapFormType }}</h3>
      <div></div>
      <div class="leyendWrap">
        <div class="leyend">
          <div id="red"></div>
          <div id="orange"></div>
          <div id="yellow"></div>
          <div id="green"></div>
          <p>
            MUY ALTO
          </p>
          <p>
            ALTO
          </p>
          <p>
            MEDIO
          </p>
          <p>
            BAJO
          </p>
        </div>
      </div>

      <exportXLSX
        v-show="!checkIfTableLoaded"
        v-bind:header="getDetailsRiskFactor"
        v-bind:body="getRiskFactorList"
        class="downloadButton"
      />
    </div>
    <div class="backgroundCard">
      <div v-show="checkIfTableLoaded">
        <base-spinner v-show="timerCount > 0" />
        <h3 v-show="timerCount < 1">
          Error al carga la información, por favor verifique su conexión a
          internet y refresque la pagina
        </h3>
      </div>
      <div class="grid-list" v-show="!checkIfTableLoaded">
        <table class="sortable" id="user-list">
          <thead>
            <tr>
              <th
                v-for="(name, id) in ExtraInfo"
                :key="id"
                v-on:click="sortBy(name)"
              >
                <div class="headerGrid">
                  <a href="#" v-bind:class="{ active: sortKey == name }">
                    {{ name }}
                  </a>
                  <img
                    :src="getSortIcon(name)"
                    alt=""
                    class="icon"
                    height="14"
                  />
                </div>
              </th>
              <th
                v-for="(_, riskName) in getDetailsRiskFactor"
                :key="riskName"
                v-on:click="sortBy(riskName)"
              >
                <div class="headerGrid">
                  <a href="#" v-bind:class="{ active: sortKey == riskName }">
                    {{
                      riskName === 'INDICE RIESGO TOTAL'
                        ? 'ÍNDICE RIESGO TOTAL'
                        : riskName
                    }}
                  </a>
                  <img
                    :src="getSortIcon(riskName)"
                    alt=""
                    class="icon"
                    height="14"
                  />
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(risk, index) in getRiskFactorList" :key="index">
              <td v-for="(name, id) in ExtraInfo" :key="id">
                {{ risk[name] }}
              </td>
              <template
                v-for="(_, riskName) in getDetailsRiskFactor"
                :key="riskName"
              >
                <template v-for="(val, valIndex) in risk" :key="valIndex">
                  <td
                    v-if="riskName === valIndex"
                    v-bind:style="backgroundColor(val, riskName)"
                  >
                    <p>{{ val }}</p>
                  </td>
                </template>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store';
import exportXLSX from '../components/table/exportTableFromFirebase.vue';
import _ from 'lodash';
export default {
  components: {
    exportXLSX
  },
  data() {
    return {
      sortKey: '',
      reverse: true,
      timerCount: 20,
      sortIcon: require('../assets/img/arrow-down-up.svg'),
      sortUpIcon: require('../assets/img/arrow-up.svg'),
      sortDownIcon: require('../assets/img/arrow-down.svg'),
      ExtraInfo: [
        'Nro. Manzana SII',
        'Nro. Predios SII',
        'Nro. ROL SII',
        'Dirección'
      ]
    };
  },
  mounted() {
    this.$store.commit('setCurrentPage', 'table');
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    },
    getRiskFactorList: {
      handler() {
        this.timerCount = 30;
      }
    }
  },
  methods: {
    sortBy: function(sortKey) {
      this.reverse = this.sortKey == sortKey ? !this.reverse : false;
      this.sortKey = sortKey;
    },
    getSortIcon: function(keyName) {
      if (this.reverse && this.sortKey === keyName) return this.sortUpIcon;
      if (!this.reverse && this.sortKey === keyName) return this.sortDownIcon;
      else return this.sortIcon;
    },

    backgroundColor(risk, name) {
      let bgc = {};
      if (name !== 'INDICE RIESGO TOTAL') return bgc;
      if (1.0 <= risk && risk <= 1.75) {
        bgc.backgroundColor = '#38a800';
      }
      if (1.75 < risk && risk <= 2.5) {
        bgc.backgroundColor = '#FFFF00';
      }
      if (2.5 < risk && risk <= 3.25) {
        bgc.backgroundColor = '#ffaa00';
      }
      if (3.25 < risk && risk <= 4.0) {
        bgc.backgroundColor = '#ff0000';
      }
      return bgc;
    }
  },
  computed: {
    getDetailsRiskFactor() {
      return this.$store.getters['getMapDetailsRiskFactorTemplate'];
    },
    getRiskFactorListIds() {
      return this.$store.getters['getRiskFactorListIds'];
    },
    getMapFormType() {
      return this.$store.getters['getFormType'];
    },
    checkIfTableLoaded() {
      if (this.$store.getters['getRiskFactorListPlusExtraData'].length === 0) {
        store.commit('setIsLayerLoading', true);
        return true;
      } else {
        store.commit('setIsLayerLoading', false);
        return false;
      }
    },
    getRiskFactorList() {
      let unsorted = this.$store.getters['getRiskFactorListPlusExtraData'];
      if (this.reverse == true)
        return _.orderBy(unsorted, this.sortKey).reverse();
      else return _.orderBy(unsorted, this.sortKey);
    }
  }
};
</script>

<style scoped>
.backgroundCard {
  margin: 1.5rem auto;
  max-width: 97%;
  vertical-align: middle;
}
.grid-container {
  display: grid;
  justify-items: center;
  height: 100%;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(250px, 2fr));
  grid-template-rows: repeat(auto-fit, minmax(250px, 2fr));
  grid-auto-rows: 2fr;
  grid-gap: 0, 0%;
  padding: 1rem;
  box-sizing: border-box;
}

.grid-list {
  height: 100%;
  width: 100%;
  align-self: center;
  font-size: 30px;
  text-align: center;
  grid-column-start: 2;
  grid-column-end: 3;
}

.grid-form {
  padding: 20px;
  margin-bottom: 3%;
  height: 100%;
  width: 100%;
  align-self: center;
  font-size: 20px;
}

.headerGrid {
  padding: 5px 3px 5px 8px;
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: 7fr 1fr;
  grid-template-rows: 1;
  box-sizing: border-box;
  text-align: center;
}
.icon {
}
.headerGrid img {
  position: relative;
  margin: auto;
}

.headerGrid a {
  color: black;
  text-decoration: none;
  text-transform: uppercase;
}

#register {
  position: relative;
  right: 27%;
}

#user-list {
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  border-collapse: collapse;
  width: 100%;
  scroll-behavior: auto;
}

#user-list th {
  font-size: 14px;
}
#user-list td {
  font-size: 13px;
}

#user-list td,
#user-list th {
  border: 1px solid black;
  padding: 0;
}

#user-list tr:nth-child(even) {
  background-color: #e2e2e2;
}
#user-list tr:nth-child(odd) {
  background-color: #fdfdfd;
}

#user-list th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #e2e2e2;
  color: black;
}

.select-css {
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  color: black;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 25%;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.select-css::-ms-expand {
  display: none;
}

.select-css:hover {
  border-color: #888;
}

.select-css option {
  font-weight: normal;
}

*[dir='rtl'] .select-css,
:root:lang(ar) .select-css,
:root:lang(iw) .select-css {
  background-position: left 0.7em top 50%, 0 0;
  padding: 0.6em 0.8em 0.5em 1.4em;
}

.select-css:disabled,
.select-css[aria-disabled='true'] {
  color: graytext;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
}

.select-css:disabled:hover,
.select-css[aria-disabled='true'] {
  border-color: #aaa;
}

#emailView {
  max-width: 420px;
  overflow: hidden;
}

#adminAccounts {
  max-height: 500px;
  overflow: auto;
}

@media only screen and (max-width: 1200px) {
  #user-list {
    font-size: 14px;
  }
}
@media only screen and (max-width: 960px) {
  #user-list {
    font-size: 11px;
  }
}
td .colorDiv {
  border: 1px solid black;
  border-width: 0 0 0 1px;
  height: 100%;
  width: 100%;
}
.titleCard {
  display: grid;
  color: black;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0.5rem 0rem 0.5rem 0rem;
  margin: 1.5rem auto;
  max-width: 97%;
  vertical-align: middle;
  grid-template-columns: 2fr 3fr 1fr 1fr;
  grid-template-rows: 1;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}
.titleWrapper {
  background-color: #ff0000;
  height: 100%;
  width: 100%;
}
.leyend {
  vertical-align: middle;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: auto auto auto auto;
  justify-items: center;
  align-items: center;
  box-sizing: border-box;
}
.leyendWrap p {
  margin: 7px;
  font-size: 13px;
}
.leyend p {
  margin: 0;
  font-size: 13px;
}
#red {
  background-color: #ff0000;
  width: 20px;
  height: 20px;
}
#orange {
  background-color: #ffaa00;
  width: 20px;
  height: 20px;
}
#yellow {
  background-color: #ffff00;
  width: 20px;
  height: 20px;
}
#green {
  background-color: #38a800;
  width: 20px;
  height: 20px;
}
.downloadButton {
}
</style>
