import firebase from 'firebase/app';
import 'firebase/firestore';

export default {
  async loadMapForms(context) {
    firebase
      .firestore()
      .collection('formsSchema')
      .get()
      .then(snap => {
        const data = [];
        const ids = [];
        snap.forEach(item => {
          data.push(item.data());
          ids.push(item.id);
        });
        context.commit('setMapForms', data);
        context.commit('setMapFormsTypes', ids);
      });
  },
  async submitFormData(context, payload) {
    try {
      const promises = [];
      context.getters['getMapDetailsIdList'].forEach((id, index) => {
        const details = context.getters['getMapDetails'][index];
        const detailsObject = {
          'Nro. Manzana SII': details[3],
          'Nro. Predios SII': details[4],
          'Nro. ROL SII': details[1],
          Dirección: details[2]
        };

        promises.push(
          firebase
            .firestore()
            .collection('riskData')
            .doc(context.getters['getFormType'])
            .collection('information')
            .doc(id)
            .set(payload)
        ),
          promises.push(
            firebase
              .firestore()
              .collection('riskDataExtraInfo')
              .doc(context.getters['getFormType'])
              .collection('information')
              .doc(id)
              .set(detailsObject)
          );
      });
      await Promise.all(promises);
    } catch (error) {
      return error;
    }
  },

  async getMapRisk(context, payload) {
    try {
      context.commit('setMapDetailsRiskFactor', null);
      firebase
        .firestore()
        .collection('riskData')
        .doc(context.getters['getFormType'])
        .collection('information')
        .doc(payload)
        .get()
        .then(snap => {
          if (snap.exists) {
            const riskObj = snap.data().factoresDeVulnerabilidad;
            return context.commit('setMapDetailsRiskFactor', riskObj);
          }
        });
    } catch (error) {
      return error;
    }
  },
  async LoadFirstRisk(context) {
    try {
      firebase
        .firestore()
        .collection('riskData')
        .doc(context.getters['getFormType'])
        .collection('information')
        .limit(1)
        .get()
        .then(snap => {
          snap.forEach(item => {
            return context.commit(
              'setMapDetailsRiskFactorTemplate',
              item.data().factoresDeVulnerabilidad
            );
          });
        });
    } catch (error) {
      return error;
    }
  },
  async loadMapRiskList(context) {
    try {
      let [riskDataSnap, extraDataSnap] = await Promise.all([
        firebase
          .firestore()
          .collection('riskData')
          .doc(context.getters['getFormType'])
          .collection('information')
          .get(),
        firebase
          .firestore()
          .collection('riskDataExtraInfo')
          .doc(context.getters['getFormType'])
          .collection('information')
          .get()
      ]);
      let extraDataObj = {};
      let data = [];
      let dataExtra = [];
      let ids = [];

      extraDataSnap.forEach(item => {
        extraDataObj[item.id] = item.data();
      });

      riskDataSnap.forEach(item => {
        data.push(item.data().factoresDeVulnerabilidad);
        ids.push(item.id);
        dataExtra.push(extraDataObj[item.id]);
      });
      context.commit('setRiskFactorList', data);
      context.commit('setRiskFactorExtraDataList', dataExtra);
      context.commit('setRiskFactorListIds', ids);
    } catch (error) {
      return error;
    }
  },
  async loadFormsDetails(context) {
    const data = {};
    firebase
      .firestore()
      .collection('formDetails')
      .get()
      .then(snap => {
        snap.forEach(item => {
          data[item.id] = item.data();
        });
        context.commit('setFormsDetails', data);
      });
  },
  async getXLSXUrl(context, payload) {
    const XLSXUrl = firebase.functions().httpsCallable('exportDataToClient');
    return await XLSXUrl(payload);
  },
  async DownloadFile(context, payload) {
    firebase
      .storage()
      .ref(payload)
      .getDownloadURL()
      .then(url => {
        window.location.assign(url);
      })
      .catch(function(error) {
        console.log(error);
      });
  },
  async OpenPdfFile(context, payload) {
    firebase
      .storage()
      .ref(payload)
      .getDownloadURL()
      .then(url => {
        window.open(url);
      })
      .catch(function(error) {
        console.log(error);
      });
  },

  async loadTooltipData(context) {
    const data = {};
    firebase
      .firestore()
      .collection('formsTooltip')
      .get()
      .then(snap => {
        snap.forEach(item => {
          data[item.id] = item.data();
        });
        context.commit('setFormTooltipData', data);
      });
  },
  async queryHistoricFilesList(context) {
    let objOfLists = {};
    const storageRef = await firebase
      .storage()
      .ref('SPM_VALPO')
      .listAll();
    storageRef.prefixes.forEach(async function(folderRef) {
      let list = [];
      const ref = await folderRef.listAll();
      ref.items.forEach(function(item) {
        list.push({ name: item.name, path: item.fullPath });
      });
      objOfLists[folderRef.name] = list;
    });
    context.commit('setHistoricList', objOfLists);
  },
  async queryTooltipImgUrls(context) {
    let objOfLists = {};
    const storageRef = await firebase
      .storage()
      .ref('TooltipImages')
      .listAll();
    storageRef.prefixes.forEach(async function(folderRef) {
      let list = [];
      const ref = await folderRef.listAll();
      ref.items.forEach(async function(item) {
        const url = await item.getDownloadURL();
        list.push({ url: url });
      });
      objOfLists[folderRef.name] = list;
    });
    console.log(objOfLists);
    context.commit('setTooltipImgUrls', objOfLists);
  }
};
