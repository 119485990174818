<template>
  <table id="mapDetails">
    <tr>
      <th>INDICADORES</th>
      <th>ÍNDICE DE RIESGO</th>
    </tr>
    <tr v-for="(risk, riskId) in getDetailsRiskFactor" :key="riskId">
      <td id="attribute" v-if="riskId != 'INDICE RIESGO TOTAL'">
        {{ riskId.toUpperCase() }}
      </td>
      <td class="number" v-if="riskId != 'INDICE RIESGO TOTAL'">
        {{ risk }}
      </td>
    </tr>
    <tr>
      <td id="attribute"><b>TOTAL</b></td>
      <td
        class="number"
        v-bind:style="
          backgroundColor(getDetailsRiskFactor['INDICE RIESGO TOTAL'])
        "
      >
        <b>{{ getDetailsRiskFactor['INDICE RIESGO TOTAL'] }}</b>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    getDetailsRiskFactor() {
      return this.$store.getters['getMapDetailsRiskFactor'];
    },
    getBackgroundColor() {
      return this.$store.getters['getBackgroundColor'];
    }
  },
  methods: {
    backgroundColor(risk) {
      let bgc = {
        backgroundColor: 'white'
      };
      if (1.0 <= risk && risk <= 1.75) {
        bgc.backgroundColor = '#38a800';
      }
      if (1.75 < risk && risk <= 2.5) {
        bgc.backgroundColor = '#FFFF00';
      }
      if (2.5 < risk && risk <= 3.25) {
        bgc.backgroundColor = '#ffaa00';
      }
      if (3.25 < risk && risk <= 4.0) {
        bgc.backgroundColor = '#ff0000';
      }
      return bgc;
    }
  }
};
</script>

<style scoped>
#mapDetails {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  background-color: rgb(240, 240, 240);
}

#mapDetails th,
b {
  font-size: 14px;
}

#mapDetails td {
  font-size: 13px;
}

#mapDetails td,
#mapDetails th {
  border: 1px solid black;
  padding: 8px;
  border: 1px solid rgb(175, 175, 175);
}

#mapDetails th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: black;
  border-top: 0px;
}
.number {
  font-weight: bold;
  color: black;
}
.number b {
  font-weight: bold;
  color: black;
  font-size: 16px;
}
#attribute {
  text-align: left;
}
</style>
