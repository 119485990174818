<template>
  <div>
    <base-dialog :show="!!alertMessage" title="Atención!" @close="handleError">
      <p>{{ alertMessage }}</p>
    </base-dialog>
    <base-backdrop :show="isLoading" title="Ejecutando..." fixed>
      <base-spinner></base-spinner>
    </base-backdrop>
    <div class="grid-container">
      <div class="grid-form">
        <base-card>
          <form @submit.prevent="submitForm">
            <div class="form-control">
              <label for="email">E-Mail</label>
              <input id="email" v-model.trim="email" />
            </div>
            <div class="form-control">
              <label for="password">Contraseña</label>
              <input type="password" id="password" v-model.trim="password" />
            </div>
            <div class="form-control">
              <label for="referrer" class="select-label"
                >Privilegio del Usuario</label
              >
              <select id="referrer" v-model="referrer" class="select-css">
                <option value="user">Editor</option>
                <option value="user-readonly">Observador</option>
                <option value="admin">Administrador</option>
              </select>
            </div>
            <p v-if="!formIsValid">{{ formErrorText }}</p>
            <base-button id="register">Agregar Usuario</base-button>
          </form>
        </base-card>
      </div>
      <base-card id="adminAccounts">
        <div class="grid-list">
          <table id="user-list">
            <tr>
              <th>Correo Electronico</th>
              <th>Roles</th>
              <th>Eliminar Cuenta</th>
            </tr>
            <base-spinner
              class="tableSpinner"
              v-show="isTableLoading"
            ></base-spinner>
            <tr
              v-for="user in getUserList"
              :key="user.id"
              v-show="!isTableLoading"
            >
              <td id="emailView">{{ user.email }}</td>
              <td>{{ toSpanish(user.role) }}</td>
              <td>
                <img
                  v-show="getCurrentUserEmail != user.email"
                  :src="image"
                  alt="Borrar"
                  width="21"
                  height="21"
                  @click="deleteUser(user)"
                />
              </td>
            </tr>
          </table>
        </div>
      </base-card>
    </div>
    <div class="historicFilesListCard">
      <historic-download-table />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: '',
      referrer: 'user',
      formErrorText: 'Ingrese un Email y contraseña correctos',
      formIsValid: true,
      isLoading: false,
      alertMessage: null,
      image: require('../assets/img/x.svg')
    };
  },
  created() {
    this.$store.dispatch('getUserList');
    this.$store.dispatch('loadMapForms');
  },
  mounted() {
    this.$store.commit('setCurrentPage', 'signup');
  },
  computed: {
    isTableLoading() {
      if (this.$store.getters['userList'].length === 0) return true;
      else return false;
    },
    getUserList() {
      return this.$store.getters['userList'];
    },
    getCurrentUserEmail() {
      return this.$store.getters['currentUserEmail'];
    }
  },
  methods: {
    toSpanish(word) {
      if (word === 'admin') return 'Administrador';
      else if (word === 'user') return 'Editor';
      else if (word === 'user-readonly') return 'Observador';
      else return word;
    },
    async submitForm() {
      this.formIsValid = true;
      if (
        this.email === '' ||
        !this.email.includes('@') ||
        this.password.length < 6
      )
        if (this.email === '') {
          this.formIsValid = false;
          this.formErrorText = 'Ingrese un email para el usuario';
          return;
        }
      if (!this.email.includes('@')) {
        this.formIsValid = false;
        this.formErrorText = 'El email debe incluir @';
        return;
      }
      if (this.password.length < 6) {
        this.formIsValid = false;
        this.formErrorText = 'La contraseña debe tener mas de 6 carateres';
        return;
      }
      this.isLoading = true;

      const response = await this.$store.dispatch('signup', {
        email: this.email,
        password: this.password,
        role: this.referrer
      });
      this.$store.dispatch('getUserList');
      this.alertMessage = response.data.message;
      this.isLoading = false;
    },
    loadUserList() {
      this.$store.dispatch('getUserList');
    },
    async deleteUser(payload) {
      this.isLoading = true;
      const response = await this.$store.dispatch('deleteUser', payload);
      this.$store.dispatch('getUserList');
      this.alertMessage = response.data.message;
      this.isLoading = false;
    },
    handleError() {
      this.alertMessage = null;
    }
  }
};
</script>

<style scoped>
form {
  margin: 0.3rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1rem;
}

.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: left;
  display: block;
  margin-left: 12.5%;
}
.select-label {
  position: relative;
  right: 100px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  align-self: left;
  display: inline;
  margin-right: 4%;
}

input,
textarea {
  display: block;
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  padding: 0.15rem;
}

input:focus,
textarea:focus {
  border-color: #006bca;
  background-color: #faf6ff;
  outline: none;
}

input {
  position: relative;
  border-radius: 20px;
  left: 10%;
  width: 80%;
  padding: 1rem 1rem 1rem 1rem;
  margin: 0;
}

.grid-container {
  display: grid;
  justify-items: center;
  height: 100%;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(250px, 2fr));
  grid-auto-rows: 2fr;
  grid-gap: 0, 5%;
  padding: 0rem;
  box-sizing: border-box;
  margin-bottom: 70px;
}

.grid-list {
  padding: 0.3rem;
  height: 100%;
  width: 100%;
  align-self: center;
  font-size: 20px;
  text-align: center;
}

.grid-form {
  padding: 0 0 0 0;
  height: 100%;
  width: 100%;
  align-self: center;
  font-size: 14px;
}

#register {
  position: relative;
  right: 25%;
}

#user-list {
  position: relative;
  font-size: 14px;
  border-collapse: collapse;
  width: 100%;
  scroll-behavior: auto;
}

#user-list td,
#user-list th {
  border: 1px solid #ddd;
  padding: 8px;
}
#user-list tr:nth-child(even) {
  background-color: #f2f2f2;
}

#user-list tr:hover {
  background-color: #ddd;
}

#user-list th {
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #006bca;
  color: white;
}
#user-list img {
  vertical-align: middle;
  border: black 1px solid;
  border-radius: 50%;
  background-color: white;
}
#user-list img:hover {
  background-color: rgb(180, 180, 180);
}

.select-css {
  font-weight: 700;
  color: black;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 25%;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;

  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
    for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
    
  */
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 0.65em auto, 100%;
}
/* Hide arrow icon in IE browsers */
.select-css::-ms-expand {
  display: none;
}
/* Hover style */
.select-css:hover {
  border-color: #888;
}
/* Focus style */
/* .select-css:focus {
  border-color: #ff5c00;
  box-shadow: 0 0 1px 1px  #ff5c00;
  box-shadow: 0 0 0 1px -moz-mac-focusring;
  color: #222; 
  outline: none;
} */

/* Set options to normal weight */
.select-css option {
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir='rtl'] .select-css,
:root:lang(ar) .select-css,
:root:lang(iw) .select-css {
  background-position: left 0.7em top 50%, 0 0;
  padding: 0.6em 0.8em 0.5em 1.4em;
}

/* Disabled styles */
.select-css:disabled,
.select-css[aria-disabled='true'] {
  color: graytext;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
}

.select-css:disabled:hover,
.select-css[aria-disabled='true'] {
  border-color: #aaa;
}

#emailView {
  max-width: 410px;
  word-wrap: break-word;
}

#adminAccounts {
  height: 350px;
  overflow: auto;
}
.tableSpinner {
  position: absolute;
  top: 400%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.historicFilesListCard {
  background-color: white;
  width: 1000px;
  height: 400px;
  margin: -70px auto;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
}
</style>
