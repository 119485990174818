<template>
  <div
    :class="{ 'content-header': type === 'header', content: type === 'home' }"
  >
    <div class="body">
      <header>
        <h2 v-if="titles !== undefined">{{ titles[stepper] }}</h2>
      </header>
      <div v-show="stepper === 0">
        <h4>Barra superior</h4>
        <p>
          En la barra superior del visor encontrarás un menú desplegable donde
          podrás seleccionar la amenaza sobre la cual quieres trabajar
          (incendio, sismo, deslizamiento de tierra y vandalismo). Una vez
          seleccionada, se cargarán los datos de de los inmuebles en el mapa y
          podrás comenzar a trabajar.
        </p>
        <p>
          En la misma barra encontrarás los botones de navegación que te
          permitirán acceder directamente al mapa, a las tablas de resultados y
          al tutorial, así como cerrar tu sesión.
        </p>
        <img
          class="alignImgToText"
          :src="tutorialNavIcons"
          alt="MapImage"
          width="705"
        />
        <h4>El mapa</h4>
        <p>
          El visor muestra en el mapa los inmuebles insertos en la zona Sitio
          Patrimonio Mundial (SPM) de Valparaíso donde se pueden diferenciar los
          índices de riesgos por amenaza representados con diferentes colores.
        </p>
        <img class="center" :src="tutorialMap" alt="MapImage" width="705" />
      </div>
      <div class="sidebarGrid" v-show="stepper === 1">
        <img class="center" :src="tutorialMapSidebar" alt="MapImage" />
        <div>
          <h4>Capas</h4>
          <p>
            En la barra lateral de la plataforma podremos encender y apagar
            diferentes capas de información como el límite SPM, el mapa de
            geológicos y de susceptibilidad del terreno.
          </p>
          <h4>Descargar captura del mapa</h4>
          <p>
            También podrás descargar una captura del mapa en formato .JPG
          </p>
          <h4>Leyenda</h4>
          <p>
            Finalmente, encontrarlas la leyenda del mapa que describe los rangos
            y niveles de riesgo de cada inmueble según los resultados de las
            evaluaciones por amenaza.
          </p>
        </div>
      </div>

      <div v-show="stepper === 2">
        <h4>Seleccion del inmueble</h4>
        <p>
          Para comenzar la evaluación de un inmueble y/o visualizar la
          información existente y resultados de índices de riesgo asociados para
          una amenaza específica, tendrás que hacer un click sobre el polígono
          correspondiente al inmueble que deseas evaluar en el mapa.
          <img
            class="center"
            :src="tutorialPoligonSelect"
            alt="MapImage"
            width="400"
          />
        </p>
        <h4>Información</h4>
        <p>
          En las tablas desplegadas encontrarás los datos del inmueble (ROL SII
          y dirección), los índices de riesgo (si existen) y el botón “Ingresar
          o modificar datos” para que puedas seguir evaluando.
        </p>
        <img
          class="center"
          :src="tutorialRiskTable"
          alt="MapImage"
          width="705"
        />
      </div>
      <div v-show="stepper === 3">
        <h4>Ingresar o editar datos de un inmueble</h4>
        <p>
          En la ficha de evaluación por inmueble podrás ingresar los datos que
          se solicitan para cada grupo de indicadores desde un menú desplegable.
          Cuando hayas ingresado todos los datos que solicitan en pantalla pulsa
          el botón “Continuar” datos que te permitirá continuar con la
          evaluación. Para finalizar pulsa “Ingresar datos”.
        </p>
        <img class="center" :src="mapForm" alt="MapImage" width="705" />
        <p>
          Junto a cada indicador encontrarás este botón
          <img class="textImg" :src="questionmarkIcon" alt="?" /> donde podrás
          encontrar una descripción adicional de ayuda para responder la
          encuesta.
        </p>
      </div>
    </div>
    <footer>
      <div v-if="type === 'home'" class="buttonWrap">
        <base-button
          v-show="stepper === 0"
          class="closeBtn"
          mode="outline"
          @click="tryClose"
          >Volver</base-button
        >
        <div class="wrap">
          <img
            v-bind:style="disableBackArrow"
            :src="arrowIcon"
            alt="Atras"
            width="40"
            height="40"
            @click="stepperDown()"
          />
          <img
            v-bind:style="disableForwardArrow"
            :src="arrowIcon"
            alt="adelante"
            width="40"
            height="40"
            @click="stepperUp()"
          />
        </div>
        <router-link
          v-show="stepper === 3"
          to="/map"
          tag="button"
          class="mapBtn"
          ><base-button mode="outline" @click="resetStepper()"
            >Ir al mapa</base-button
          ></router-link
        >
      </div>
      <div v-if="type === 'header'" class="buttonWrapHeader">
        <div class="wrap">
          <img
            v-bind:style="disableBackArrow"
            :src="arrowIcon"
            alt="Atras"
            width="40"
            height="40"
            @click="stepperDown()"
          />
          <img
            v-bind:style="disableForwardArrow"
            :src="arrowIcon"
            alt="adelante"
            width="40"
            height="40"
            @click="stepperUp()"
          />
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datos: {},
      isLoading: false,
      error: null,
      stepper: 0,
      arrowIcon: require('../../assets/img/caret-left.svg'),
      questionmarkIcon: require('../../assets/img/question-square.svg'),
      tutorialMap: require('../../assets/img/tutorial/map.png'),
      tutorialMapSidebar: require('../../assets/img/tutorial/mapSidebar.png'),
      tutorialPoligonSelect: require('../../assets/img/tutorial/poligonSelect.png'),
      tutorialRiskTable: require('../../assets/img/tutorial/riskTable.png'),
      tutorialExportMap: require('../../assets/img/tutorial/exportMap.png'),
      tutorialNavIcons: require('../../assets/img/tutorial/navIcons.png'),
      tutorialDataTable: require('../../assets/img/tutorial/dataTable.png'),
      extraLayer: require('../../assets/img/tutorial/extraLayer.png'),
      mapForm: require('../../assets/img/tutorial/mapForm.png')
    };
  },
  props: {
    type: {
      type: String,
      required: false,
      default: 'home'
    },
    titles: {
      type: Array,
      required: false,
      default: undefined
    }
  },
  async created() {},
  mounted() {
    this.datos = {};
  },

  emits: ['close'],
  computed: {
    getFormBody() {
      return this.$store.getters['getFormBody'];
    },
    getFormDetails() {
      return this.$store.getters['getFormDetails'];
    },
    disableBackArrow() {
      if (this.stepper === 0)
        return {
          filter: 'grayscale(100%)'
        };
      else
        return {
          filter: 'grayscale(0)'
        };
    },
    disableForwardArrow() {
      if (this.stepper === 3)
        return {
          filter: 'grayscale(100%)',
          transform: 'scaleX(-1)'
        };
      else
        return {
          filter: 'grayscale(0)',
          transform: 'scaleX(-1)'
        };
    }
  },
  methods: {
    tryClose() {
      this.$emit('close');
    },
    resetStepper() {
      this.$store.commit('setTutorialStepper', 0);
    },
    stepperUp() {
      if (this.stepper < 3) {
        this.stepper++;
        this.$store.commit('setTutorialStepper', this.stepper);
      }
    },
    stepperDown() {
      if (this.stepper > 0) {
        this.stepper--;
        this.$store.commit('setTutorialStepper', this.stepper);
      }
    }
  }
};
</script>
<style scoped>
.content {
  position: fixed;
  color: black;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  margin: 2rem 0;
  width: 50rem;
  height: 620px;
  vertical-align: middle;
  left: 50%;
  transform: translate(-50%, 0);
  display: grid;
  grid-template-rows: 8fr 1fr;
  overflow-x: hidden;
  text-align: center;
  height: 80vh;
}
.content-header {
  display: grid;
  grid-template-rows: 15fr 1fr;
  overflow-x: hidden;
  text-align: center;
  height: 80vh;
}
.body {
  grid-row: 1;
  overflow-y: auto;
  line-height: 130%;
}
footer {
  grid-row: 2;
  height: 50px;
  margin: auto;
  overflow: hidden;
}
.sidebarGrid {
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 3fr;
  padding: 0 40px 0 40px;
}
.sidebarGrid img {
  margin: 15px 0 0 0;
  grid-column: 1;
}
.sidebarGrid .homeImg {
  margin: 15px 0 0 0;
  grid-column: 1;
}
.sidebarGrid div {
  grid-column: 2;
}
.sidebarGrid div h4 {
  padding-top: 60px;
}
.titleCounter {
  background-color: rgb(167, 167, 167);
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}
.content .buttonWrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  position: relative;
  bottom: 0;
  width: 730px;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  align-content: center;
}
.buttonWrap .wrap {
  grid-column: 2;
  grid-row: 1;
  margin-top: 5px;
}
.mapBtn {
  right: 0%;
  position: absolute;
  grid-column: 3;
}
.closeBtn {
  position: absolute;
  grid-column: 1;
}
.buttonWrapHeader {
  position: relative;
  margin-top: 13px;
}

h2 {
  text-align: left;
  padding-left: 5%;
}
#stepper {
  position: relative;
  float: right;
  right: 20px;
}
.center {
  margin: 30px 0 30px 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.centerAndBorder {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid black;
}

.alignImgToText {
  margin: 30px 0 20px 0;
}

.alignImgToTextAndBorder {
  border: 1px solid black;
}

p {
  text-align: justify;
  font-size: 14px;
  padding-left: 5%;
  padding-right: 5%;
}
h4 {
  text-align: justify;
  padding-left: 5%;
  padding-right: 5%;
}
.textImg {
  vertical-align: sub;
}
</style>
