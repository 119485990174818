import { createApp } from 'vue';
import firebase from 'firebase/app';
import 'firebase/auth';

import router from './router.js';
import App from './App.vue';
import store from './store/index.js';
import BaseCard from './components/UI/BaseCard.vue';
import BaseBackdrop from './components/UI/BaseBackdrop.vue';
import BaseBadge from './components/UI/BaseBadge.vue';
import BaseButton from './components/UI/BaseButton.vue';
import BaseDialog from './components/UI/BaseDialog.vue';
import BaseModal from './components/UI/BaseModal.vue';
import BaseDialogForm from './components/UI/BaseDialogForm.vue';
import BaseSpinner from './components/UI/BaseSpinner.vue';
import TutorialBody from './components/layout/tutorialBody.vue';
import historicDownloadTable from './components/layout/historicDownloadTable.vue';

const firebaseConfig = {
  apiKey: 'AIzaSyAgZAtNWlrOT5PMtVBLRX2B1ZKqaTEUawM',
  authDomain: 'plataforma-gestion-riesgo-valp.firebaseapp.com',
  databaseURL: 'https://plataforma-gestion-riesgo-valp.firebaseio.com',
  projectId: 'plataforma-gestion-riesgo-valp',
  storageBucket: 'plataforma-gestion-riesgo-valp.appspot.com',
  messagingSenderId: '1035841768576',
  appId: '1:1035841768576:web:8630bb47402513e8c52a65'
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const app = createApp(App);

app.use(router);
app.use(store);

app.component('base-card', BaseCard);
app.component('base-backdrop', BaseBackdrop);
app.component('base-button', BaseButton);
app.component('base-badge', BaseBadge);
app.component('base-dialog', BaseDialog);
app.component('base-modal', BaseModal);
app.component('base-dialog-form', BaseDialogForm);
app.component('base-spinner', BaseSpinner);
app.component('tutorial-body', TutorialBody);
app.component('historic-download-table', historicDownloadTable);

app.mount('#app');

// Checks if the user token changed in some way
firebase.auth().onIdTokenChanged(user => {
  if (user) {
    store.commit('setCurrentUserEmail', user.email);
    user
      .getIdTokenResult()
      .then(token => {
        if (token.claims.role === 'user')
          store.commit('setUserReadOnly', false);
        if (token.claims.role === 'user-readonly')
          store.commit('setUserReadOnly', true);
        if (token.claims.role === 'admin') {
          router.push('/adminSignup');
        } else if (
          token.claims.role === 'user' ||
          token.claims.role === 'user-readonly'
        ) {
          if (
            router.currentRoute.value.path != '/home' &&
            router.currentRoute.value.path != '/map' &&
            router.currentRoute.value.path != '/table'
          ) {
            router.push('/home');
          }
        } else {
          router.push('/');
        }
      })
      .catch(err => console.log(err));
  } else {
    store.commit('setCurrentUserEmail', '');
    router.push('/');
  }
});
