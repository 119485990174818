<template>
  <div class="btnWrapper">
    <base-backdrop :show="isLoading" title="Descargando..." fixed>
      <base-spinner></base-spinner>
    </base-backdrop>
    <base-button v-on:click="requestXLSX()"
      ><div class="btnTitle">Descargar Reporte</div>
      <b class="btnSubTitle">(Formato excel)</b></base-button
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      error: null
    };
  },
  methods: {
    async requestXLSX() {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('getXLSXUrl', {
          riskType: this.$store.getters['getFormType']
        });
        await this.$store.dispatch('DownloadFile', response.data.url);
        this.isLoading = false;
      } catch (err) {
        this.error =
          err.message ||
          'Error al tratar de conseguir la información de la base de datos.';
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.btnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
button {
  padding: 6px 8px 6px 6px;
  margin: 0;
}
.btnTitle {
  font-size: 18px;
}
.btnSubTitle {
  font-size: 14px;
}
</style>
