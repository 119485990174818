<template>
  <div>
    <button
      :class="buttonStyle"
      :disabled="isLayerLoading"
      @click="loadLayer()"
    >
      {{ name }}
    </button>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/storage';

import KML from 'ol/format/KML';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
export default {
  props: {
    name: {
      type: String,
      required: false
    },
    dbName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      buttonStyle: 'button',
      isClicked: false
    };
  },
  computed: {
    isLayerLoading() {
      return this.$store.getters['getIsLayerLoading'];
    },
    FormCurrentTypeChange() {
      return this.$store.getters['getFormCurrentType'];
    }
  },
  watch: {
    FormCurrentTypeChange: {
      handler() {
        this.buttonStyle = 'button';
        this.isClicked = false;
      }
    }
  },
  methods: {
    async loadLayer() {
      let map = this.$store.getters['getTheMapObject'];
      this.$store.commit('setIsLayerLoading', true);
      if (this.isClicked === false) {
        try {
          // Load kml layer
          var URL = await firebase
            .storage()
            .ref(`ExtraLayers/${this.dbName}.kml`)
            .getDownloadURL();
          let kml = new VectorLayer({
            source: new VectorSource({
              url: URL,
              format: new KML()
            })
          });
          this.buttonStyle = 'buttonActive';
          kml.set('name', this.dbName); //Set name so we can delete it later

          map.addLayer(kml);
        } catch (error) {
          console.log(error);
          this.isClicked = !this.isClicked;
        }
      } else {
        //Delete layer
        this.buttonStyle = 'button';
        this.$store.commit('setIsLayerLoading', true);

        map.getLayers().forEach(layer => {
          if (layer !== undefined) {
            if (layer.get('name') && layer.get('name') == this.dbName) {
              layer.dispose();
            }
          }
        });
      }
      this.isClicked = !this.isClicked;
      this.$store.commit('setTheMap', map);
      this.$store.commit('setIsLayerLoading', false);
    }
  }
};
</script>

<style scoped>
button {
  border: solid 1px;
  border-radius: 5px;

  width: 95%;
  padding-bottom: 6px;
  padding-top: 6px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
}

.button {
  background-color: #ffeee8;
  border-color: #ff5c00;
  color: #ff5c00;
}
.button:hover:enabled {
  background-color: #ffc6b1;
  border-color: #ff5c00;
  color: #ff5c00;
}
.buttonActive {
  background-color: #ff5c00;
  color: white;
}
.buttonActive:hover:enabled {
  background-color: #e25300;
  color: white;
}

.loadingSpinner {
  position: fixed;
  top: 130px;
  left: 200px;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  z-index: 1500;
}
</style>
