<template>
  <div id="app">
    <map-sidebar />
    <map-container id="map" />
    <base-dialog-form :show="isFormActive" @close="closeForm">
      <map-form />
    </base-dialog-form>
    <div id="details" v-if="getDetailsId != ''">
      <h3>Información del inmueble</h3>
      <map-details />
      <div id="detailsRisk" v-if="getDetailsRiskFactor != null">
        <map-details-risk />
      </div>
      <base-button
        v-if="UserReadOnly === false"
        id="submit"
        v-on:click="isFormActive = !isFormActive"
      >
        <h5 class="mapFormgGreyButtonText" v-if="getDetailsRiskFactor != null">
          Editar datos
        </h5>
        <h5 class="mapFormgGreyButtonText" v-else>
          Ingresar datos
        </h5>
      </base-button>
      <div v-if="UserReadOnly === true" class="MarginBottom"></div>
    </div>
  </div>
</template>

<script>
import MapContainer from '../components/map/TheMap.vue';
import mapForm from '../components/map/TheMapForm.vue';
import mapDetails from '../components/map/mapDetails.vue';
import mapDetailsRisk from '../components/map/mapDetailsRisk.vue';
import mapSidebar from '../components/map/TheSidebar.vue';
import BaseButton from '../components/UI/BaseButton.vue';
export default {
  components: {
    MapContainer,
    mapForm,
    mapDetails,
    BaseButton,
    mapDetailsRisk,
    mapSidebar
  },
  data() {
    return {
      isFormActive: false
    };
  },
  computed: {
    getDetailsId() {
      return this.$store.getters['getMapDetailsIdList'];
    },
    getDetailsRiskFactor() {
      return this.$store.getters['getMapDetailsRiskFactor'];
    },
    UserReadOnly() {
      return this.$store.getters['getUserReadOnly'];
    }
  },
  created() {
    this.$store.dispatch('loadTooltipData');
    this.$store.dispatch('queryTooltipImgUrls');
  },
  async mounted() {
    this.$store.commit('setCurrentPage', 'map');
    await this.$store.dispatch('loadMapForms');
    await this.$store.dispatch('loadFormsDetails');
  },
  methods: {
    closeForm() {
      this.isFormActive = false;
    }
  }
};
</script>

<style>
#map {
  position: fixed;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
#details {
  position: absolute;
  /* background-color: white; */
  top: 115px;
  right: 2%;
  z-index: +2;
  min-width: 448px;
  background-color: white;
  padding: 0px 12px 0px 12px;
  border: 1px solid rgb(175, 175, 175);
}
#detailsRisk {
  z-index: +2;
}

#submit {
  margin: 12px;
}
#submit:hover {
  filter: invert(1);
  background-color: rgb(60, 60, 60);
}
.mapFormgGreyButtonText {
  font-size: 13px;
  margin: 0px;
  padding: 0;
}
.MarginBottom {
  margin-bottom: 20px;
}
</style>
