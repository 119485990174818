import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {

  state() {
    return{
      userEmail: '',
      userRole: null,
      userList:[],
      currentPage: '',
      userReadOnly: true,
    }
  },
  mutations,
  actions,
  getters
};