<template>
  <div ref="map-root">
    <div v-show="isError" class="errorMesage">
      <b>Error al cargar el mapa, por favor refresque la pagina</b>
      <img class="grid-item-btn" :src="xIcon" alt="Close" @click="tryClose" />
    </div>
    <base-spinner v-show="isLayerLoading === true" class="loadingSpinner" />
  </div>
</template>

<script>
import store from '../../store';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

import View from 'ol/View';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import KML from 'ol/format/KML';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import * as olProj from 'ol/proj';
import { Fill, Stroke, Style } from 'ol/style';
import Select from 'ol/interaction/Select';
import Collection from 'ol/Collection';
import { click } from 'ol/events/condition';

// importing the OpenLayers stylesheet is required for having
// good looking buttons!
import 'ol/ol.css';

export default {
  name: 'MapContainer',
  components: {},
  data() {
    return {
      isWorking: false,
      isError: false,

      xIcon: require('../../assets/img/x.svg')
    };
  },
  methods: {
    tryClose() {
      this.isError = false;
    }
  },
  mounted() {
    // FUNCTION TO SEARCH FOR THE VARIABLES IN THE KML STRING
    function getIndicesOf(searchStr, str, caseSensitive) {
      var searchStrLen = searchStr.length;
      if (searchStrLen == 0) {
        return [];
      }
      var startIndex = 0,
        index,
        indices = [];
      if (!caseSensitive) {
        str = str.toLowerCase();
        searchStr = searchStr.toLowerCase();
      }
      while ((index = str.indexOf(searchStr, startIndex)) > -1) {
        indices.push(index);
        startIndex = index + searchStrLen;
      }
      return indices;
    }
    function getDataFromDom(dom) {
      let start = getIndicesOf('<td>', dom);
      let end = getIndicesOf('</td>', dom);
      start.splice(0, 2);
      end.splice(0, 1);
      end.splice(end.length - 1, 1);

      let payload = [];
      let odd = false;
      start.forEach((element, index) => {
        if (odd) {
          payload.push(dom.slice(element + 4, end[index]));
          odd = false;
        } else odd = true;
      });
      return payload;
    }
    const tileLayer = new TileLayer({
      source: new OSM()
    });
    const map = new Map({
      target: this.$refs['map-root'],
      layers: [tileLayer],

      view: new View({
        zoom: 16.5,
        center: olProj.fromLonLat([-71.6285, -33.039782]),
        constrainResolution: true
      })
    });
    var featuresColl = new Collection();
    var selectClick = new Select({
      condition: click,
      features: featuresColl,
      layers: function(layer) {
        if (layer.get('name') == 'zonaUnesco') return false;
        else return true;
      }
    });
    map.addInteraction(selectClick);
    //ON CLICK FUNCTION FOR THE MAP
    map.on('click', function(evt) {
      if (evt.dragging) {
        return;
      }
      let list = [];
      let featureIdList = [];
      let hasFeature = false;
      var pixel = map.getEventPixel(evt.originalEvent);
      map.forEachFeatureAtPixel(pixel, function(feature, layer) {
        if (layer.get('name') && layer.get('name') == 'mapLayer') {
          //get data from the DOM in the description
          const kmlData = feature.get('description');
          const variables = getDataFromDom(kmlData);

          featureIdList.push(feature.id_);
          list.push(variables);
          hasFeature = true;
        }
      });

      //Sends data to VUEX
      if (hasFeature == false) {
        store.commit('setMapDetails', [[]]);
        store.commit('setMapDetailsIdList', []);
        store.commit('setMapDetailsRiskFactor', null);
      } else {
        store.commit('setMapDetails', list);
        store.commit('setMapDetailsIdList', featureIdList);
        store.dispatch('getMapRisk', featureIdList[0]);
      }
    });
    //commit the map to vuex for global usage
    store.commit('setTheMap', map);
  },
  computed: {
    getMap() {
      return this.$store.getters['getTheMapObject'];
    },
    isLayerLoading() {
      return this.$store.getters['getIsLayerLoading'];
    },
    getSelectedForm() {
      return [
        this.$store.getters['getFormType'],
        this.$store.getters['currentPage']
      ];
    }
  },
  watch: {
    async getSelectedForm(newForm) {
      let map = this.$store.getters['getTheMapObject'];
      if (newForm[0] != undefined && this.isWorking === false) {
        this.isWorking = true;
        store.commit('setIsLayerLoading', true);
        //Deletes all named layers
        map.getLayers().forEach(layer => {
          if (layer !== undefined && layer.get('name')) {
            layer.dispose();
          }
        });
        //LOADS KML LAYER
        let kmlSource = null;
        await firebase
          .storage()
          .ref(`Zonas/${newForm[0]}/doc.kml`)
          .getDownloadURL()
          .then(URL => {
            let kml = new VectorLayer({
              source: new VectorSource({
                url: URL,
                overlaps: false,
                format: new KML({
                  extractStyles: false
                })
              })
            });
            kml.set('name', 'mapLayer'); //Set name so we can delete it later
            map.addLayer(kml);
            kmlSource = kml.getSource();
          })
          .catch(() => {
            this.isError = true;
            store.commit('setIsLayerLoading', false);
          });
        kmlSource.once('change', () => {
          if (kmlSource.getState() == 'ready') {
            let duplicatedarray = [];
            firebase
              .firestore()
              .collection('riskDataExtraInfo')
              .doc(newForm[0])
              .collection('information')
              .get()
              .then(snap => {
                snap.forEach(x => {
                  const obj = {};
                  obj.key =
                    x.data()['Nro. Manzana SII'] + x.data()['Nro. Predios SII'];
                  obj.id = x.id;
                  duplicatedarray.push(obj);
                });

                const uniqByProp = prop => arr =>
                  Object.values(
                    arr.reduce(
                      (acc, item) => (
                        item && item[prop] && (acc[item[prop]] = item), acc
                      ), // using object mutation (faster)
                      {}
                    )
                  );
                const uniqueByKey = uniqByProp('key');
                const uniqueObjects = uniqueByKey(duplicatedarray);
                firebase
                  .firestore()
                  .collection('riskData')
                  .doc(newForm[0])
                  .collection('information')
                  .get()
                  .then(snap => {
                    snap.forEach(doc => {
                      let duplicated = true;
                      uniqueObjects.forEach(element => {
                        if (element.id === doc.id) {
                          duplicated = false;
                        }
                      });
                      if (duplicated === false) {
                        const feature = kmlSource.getFeatureById(doc.id);
                        const risk = doc.data().factoresDeVulnerabilidad[
                          'INDICE RIESGO TOTAL'
                        ];
                        let borderColor = '';
                        let fillColor = '';
                        if (1.0 <= risk && risk <= 1.75) {
                          borderColor = 'black';
                          fillColor = '#38a800';
                        }
                        if (1.75 < risk && risk <= 2.5) {
                          borderColor = 'black';
                          fillColor = '#FFFF00';
                        }
                        if (2.5 < risk && risk <= 3.25) {
                          borderColor = 'black';
                          fillColor = '#ffaa00';
                        }
                        if (3.25 < risk && risk <= 4.0) {
                          borderColor = 'black';
                          fillColor = '#ff0000';
                        }
                        // THIS CAN CHANGE THE COLOR OF A FEATURE
                        if (feature != null) {
                          feature.setStyle([
                            new Style({
                              fill: new Fill({
                                color: fillColor
                              }),
                              stroke: new Stroke({
                                color: borderColor
                              }),
                              zIndex: 1
                            })
                          ]);
                        }
                      }
                    });
                    store.commit('setIsLayerLoading', false);
                    this.isWorking = false;
                  });
              });
          }
        });
      }
      this.$store.commit('setTheMap', map);
    }
  }
};
</script>
<style scoped>
.loadingSpinner {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  z-index: 150;
}
.errorMesage {
  background-color: azure;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 10px 50px 10px 20px;
  margin: 20px auto;
  max-width: 97%;
  vertical-align: middle;
  border-style: solid;
  border-color: black;
  position: fixed;
  top: 120px;
  left: 55%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  z-index: 150;
}
img {
  position: absolute;
  top: 7px;
  right: 6px;
  width: 25px;
  height: 25px;
}

img:hover {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
</style>
