<template>
  <div>
    <base-dialog :show="!!error" title="Ocurrio un Error" @close="handleError">
      <p>{{ error }}</p>
    </base-dialog>
    <base-backdrop :show="isLoading" title="Autentificando..." fixed>
      <base-spinner></base-spinner>
    </base-backdrop>
    <div>
      <base-card>
        <form @submit.prevent="submitForm">
          <div class="form-control">
            <label for="resetCode">Codigo para cambiar contraseña</label>
            <input type="resetCode" id="resetCode" v-model.trim="resetCode" />
          </div>
          <div class="form-control">
            <label for="verifyPassword">Nueva Contraseña</label>
            <input
              type="verifyPassword"
              id="verifyPassword"
              v-model.trim="verifyPassword"
            />
          </div>
          <div class="form-control">
            <label for="newPassword">Reingrese su contraseña</label>
            <input
              type="newPassword"
              id="newPassword"
              v-model.trim="newPassword"
            />
          </div>
          <p v-if="!formIsValid">
            La clave debe ser mayor a 6 caracteres e igual en ambos campos
          </p>
          <base-button>Submit</base-button>
        </form>
      </base-card>
    </div>
  </div>
</template>

<script>
export default {
  props: ['email'],
  data() {
    return {
      resetCode: '',
      newPassword: '',
      verifyPassword: '',
      formIsValid: true,
      isLoading: false,
      error: null
    };
  },
  computed: {},
  methods: {
    async submitForm() {
      this.formIsValid = true;
      if (
        this.newPassword === this.verifyPassword ||
        this.verifyPassword.length < 6 ||
        this.newpassword.length < 6 ||
        this.resetCode.length <= 0
      ) {
        this.formIsValid = false;
        return;
      }
      this.isLoading = true;
      try {
        await this.$store.dispatch('PasswordResetConfirmation', {
          email: this.email,
          password: this.newPassword,
          resetCode: this.resetCode
        });
      } catch (err) {
        this.error =
          err.message || 'Fallo agregando al nuevo usuario, intentar luego';
      }
      this.isLoading = false;
    },
    handleError() {
      this.error = null;
    }
  }
};
</script>

<style scoped>
form {
  margin: 1rem;
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 1rem;
}

div.form {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}

input,
textarea {
  display: block;
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  padding: 0.15rem;
}

input:focus,
textarea:focus {
  border-color: #3d008d;
  background-color: #faf6ff;
  outline: none;
}
</style>
