<template>
  <div>
    <h3>Nivel de Riesgo</h3>
    <table id="mapDetails">
      <tr>
        <td id="red"></td>
        <td>
          <div>MUY ALTO</div>
          <div class="numbers">[3.26 - 4.00]</div>
        </td>
      </tr>
      <tr>
        <td id="orange"></td>
        <td>
          <div>ALTO</div>
          <div class="numbers">[2.51 - 3.25]</div>
        </td>
      </tr>
      <tr>
        <td id="yellow"></td>
        <td>
          <div>MEDIO</div>
          <div class="numbers">[1.76 - 2.50]</div>
        </td>
      </tr>
      <tr>
        <td id="green"></td>
        <td>
          <div>BAJO</div>
          <div class="numbers">[1.00 - 1.75]</div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
div {
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  margin-left: 15px;
}
#mapDetails {
  width: 100%;
  opacity: 1;
  border-spacing: 0 5px;
  margin-left: 12px;
}

#mapDetails td,
#mapDetails th {
  /* border: 1px solid #ddd; */
  padding: 5px;
}

#mapDetails td {
  font-size: 90%;
  text-align: left;
  padding-left: 8px;
}
#red {
  background-color: #ff0000;
  width: 25px;
  height: 25px;
}
#orange {
  background-color: #ffaa00;
  width: 20px;
  height: 20px;
}
#yellow {
  background-color: #ffff00;
  width: 20px;
  height: 20px;
}
#green {
  background-color: #38a800;
  width: 20px;
  height: 20px;
}
h3 {
  margin: 0 15px 10px 0;
}
.numbers {
  font-size: 12px;
}
</style>
