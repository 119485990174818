<template>
  <div>
    <base-backdrop :show="isLoading" title="Descargando..." fixed>
      <base-spinner></base-spinner>
    </base-backdrop>
    <base-button v-on:click="requestXLSX()">
      <div class="btnTitle">Descargar manual</div>
    </base-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      error: null,
      pdfIcon: require('../../assets/img/pdfIcon.png')
    };
  },
  methods: {
    async requestXLSX() {
      this.isLoading = true;
      try {
        await this.$store.dispatch('OpenPdfFile', 'documents/GoodDay!.pdf');
        this.isLoading = false;
      } catch (err) {
        this.error =
          err.message ||
          'Error al tratar de conseguir la información de la base de datos.';
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
img {
  margin: 0 0 10px 0;
}
</style>
