export default {
    userList(state) {
        return state.userList;
    },
    hasUsers(state) {
        return state.userList && state.userlist.length > 0;
    },
    currentPage(state) {
        return state.currentPage;
    },
    currentUserEmail(state) {
        return state.userEmail;
    },
    getUserReadOnly(state) {
        return state.userReadOnly;
    }
};