<template>
  <teleport to="body">
    <div v-if="show" class="backdrop"></div>
    <dialog open v-if="show">
      <header>
        <slot name="header">
          <img class="xButton" :src="xIcon" alt="Close" @click="tryClose" />
          <h3>EVALUACIÓN RIESGO DE {{ getCurrentType }}:</h3>
          <h3>Inmueble {{ getDetails[1] }}</h3>
        </slot>
      </header>
      <section>
        <slot></slot>
      </section>
    </dialog>
  </teleport>
</template>

<script>
export default {
  data() {
    return {
      xIcon: require('../../assets/img/x.svg')
    };
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['close'],
  methods: {
    tryClose() {
      if (this.fixed) {
        return;
      }
      this.$emit('close');
    }
  },
  computed: {
    getDetails() {
      return this.$store.getters['getMapDetails'][0];
    },
    getCurrentType() {
      return this.$store.getters['getFormType'].toUpperCase();
    }
  }
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 99;
}

dialog {
  position: fixed;
  top: 10vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: white;
  max-height: 80%;
  overflow: auto;
}

header {
  background-color: #006bca;
  color: white;
  width: 100%;
  padding: 1rem;
}

header h3 {
  margin: 0;
}

section {
  padding: 1rem;
}

header img {
  float: right;
  width: 25px;
  height: 25px;
}

header img:hover {
  background-color: rgba(255, 255, 255, 0.26);
  border-radius: 5px;
}

menu {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
.btn {
  position: relative;
  margin: 0;
  right: 20px;
  bottom: 10px;
}
.xButton {
  filter: invert(1);
}
</style>
