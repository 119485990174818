import { createRouter, createWebHistory } from 'vue-router';

import adminSignup from './pages/adminSignup.vue';
import login from './pages/login.vue';
import passwordReset from './pages/passwordReset.vue';
import map from './pages/map.vue';
import home from './pages/home.vue';
import notFound from './pages/NotFound.vue';
import riskTable from './pages/riskTable.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/login' },
    { path: '/adminSignup', component: adminSignup },
    { path: '/login', component: login },
    { path: '/passwordReset/:email', component: passwordReset, props: true},
    { path: '/home', component: home },
    { path: '/map', component: map },
    { path: '/notFound', component: notFound },
    { path: '/table', component: riskTable }
  ]
});

export default router;
