<template>
  <div>
    <base-backdrop :show="isLoading" title="Renderizando Mapa..." fixed>
      <base-spinner></base-spinner>
    </base-backdrop>
    <div v-on:click="toPdf()" class="printButton">
      <p>Descargar captura del mapa</p>
    </div>
  </div>
</template>

<script>
import { jsPDF } from 'jspdf';
export default {
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    isLayerLoading() {
      return this.$store.getters['getIsLayerLoading'];
    }
  },
  methods: {
    toPdf: function() {
      if (this.isLayerLoading === false) {
        this.isLoading = true;
        document.body.style.cursor = 'progress';

        var dims = {
          a0: [1189, 841],
          a1: [841, 594],
          a2: [594, 420],
          a3: [420, 297],
          a4: [297, 210],
          a5: [210, 148]
        };
        var format = 'a2';
        var resolution = 200;
        var dim = dims.a2;
        var width = Math.round((dim[0] * resolution) / 25.4);
        var height = Math.round((dim[1] * resolution) / 25.4);
        var map = this.$store.getters['getTheMapObject'];
        var size = map.getSize();
        var viewResolution = map.getView().getResolution();

        const promiseMapRender = new Promise(resolve => {
          map.once('rendercomplete', function() {
            var mapCanvas = document.createElement('canvas');
            mapCanvas.width = width;
            mapCanvas.height = height;
            var mapContext = mapCanvas.getContext('2d');
            Array.prototype.forEach.call(
              document.querySelectorAll('.ol-layer canvas'),
              function(canvas) {
                if (canvas.width > 0) {
                  var opacity = canvas.parentNode.style.opacity;
                  mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
                  var transform = canvas.style.transform;
                  // Get the transform parameters from the style's transform matrix
                  var matrix = transform
                    .match(/^matrix\(([^(]*)\)$/)[1]
                    .split(',')
                    .map(Number);
                  // Apply the transform to the export map context
                  CanvasRenderingContext2D.prototype.setTransform.apply(
                    mapContext,
                    matrix
                  );
                  mapContext.drawImage(canvas, 0, 0);
                }
              }
            );
            var pdf = new jsPDF('landscape', undefined, format);
            pdf.addImage(
              mapCanvas.toDataURL('image/jpeg'),
              'JPEG',
              0,
              0,
              dim[0],
              dim[1]
            );
            pdf.save('map.pdf');
            // Reset original map size
            map.setSize(size);
            map.getView().setResolution(viewResolution);
            resolve(true);
          });
        });

        promiseMapRender.then(() => {
          this.isLoading = false;
          this.$store.commit('setTheMap', map);
        });

        document.body.style.cursor = 'auto';
        // Set print size
        var printSize = [width, height];
        map.setSize(printSize);
        var scaling = Math.min(width / size[0], height / size[1]);
        map.getView().setResolution(viewResolution / scaling);
      }
    }
  }
};
</script>

<style scoped>
.printButton {
  border: none;
  border-radius: 5px;
  border: solid 1px;
  background-color: #ffeee8;
  border-color: #ff5c00;
  color: #ff5c00;
  width: 85%;
  padding-top: 3px;
  padding-bottom: 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
}
.printButton p {
  pointer-events: none;
}
.cameraIcon {
}
.printButton:hover {
  background-color: #ffc6b1;
}
</style>
