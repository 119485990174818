import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  state() {
    return {
      theMap: null,
      mapForms: [],
      mapFormsTypes: [],
      mapFormCurrentType: 0,
      mapFormDetails: {},

      mapDetailsIdList: [],
      mapDetails: [[]],
      mapDetailsRiskFactor: null,
      mapDetailsRiskFactorTemplate: null,

      RiskFactorList: [],
      RiskFactorExtraDataList: [],
      RiskFactorListIds: [],

      tutorialStepper: 0,

      formTooltipData: {},
      tooltipImgUrlsList: {},

      isLayerLoading: true,

      historicFilesList: {}
    };
  },
  mutations,
  actions,
  getters
};
