<template>
<div>
    <the-header></the-header>
    <router-view></router-view>
  </div>
</template>

<script>
import TheHeader from './components/layout/TheHeader.vue';
export default {
  components: {
    TheHeader
  },
  async created() {
    document.title = "Plataforma gestion riesgo patrimonio de valparaiso"
    await this.$store.dispatch('loadMapForms');
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&family=Playfair+Display&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
* {
  box-sizing: border-box;
  font-family: 'roboto', sans-serif;
}
* h1, * h2,* h3{
  font-family: 'roboto slab', sans-serif;
}


html {
}

body {
  font-family: 'oswald', sans-serif;
  background: url('./assets/img/Valparaiso.jpg') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin: 0;
}
</style>
