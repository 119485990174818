<template>
  <span
    :style="{ visibility: formTooltip[title] ? 'visible' : 'hidden' }"
    v-bind:id="'formTooltip-' + title"
    class="tooltip"
  >
    <img
      div
      class="js-noMenu"
      :src="tooltipImg"
      alt="?"
      @touch="updatePosition()"
      @mouseover="updatePosition()"
    />
    <span>
      <div v-if="formTooltip[title]">
        <h1>{{ title }}</h1>
        <p>
          {{ formTooltip[title].info }}
        </p>
        <ul v-if="formTooltip[title].list">
          <li v-for="item in formTooltip[title].list" :key="item">
            {{ item }}
          </li>
        </ul>
        <img
          v-for="img in formTooltipImages[title]"
          :key="img"
          :src="img.url"
          alt=""
          width="410"
        />
      </div>
    </span>
  </span>
</template>

<script>
export default {
  data() {
    return {
      Y: '',
      X: '',
      flip: '-95%',
      tooltipImg: require('../../assets/img/question-square.svg')
    };
  },
  mounted() {
    [...document.querySelectorAll('.js-noMenu')].forEach(el =>
      el.addEventListener('contextmenu', e => e.preventDefault())
    );
  },
  props: {
    title: {
      type: String,
      required: false
    }
  },
  emits: ['close'],
  computed: {
    formTooltip() {
      return this.$store.getters['getFormTooltipData'];
    },
    formTooltipImages() {
      console.log(this.$store.getters['getTooltipImgUrls'][this.title]);
      return this.$store.getters['getTooltipImgUrls'];
    }
  },
  methods: {
    closeForm() {
      this.isFormActive = false;
    },
    updatePosition() {
      const element = document.getElementById('formTooltip-' + this.title);
      const rect = element.getBoundingClientRect();
      this.X = rect.left.toString() + 'px';
      this.Y = ((rect.top + rect.bottom) / 2).toString() + 'px';

      if ((rect.top + rect.bottom) / 2 > 100) this.flip = '0%';
      if ((rect.top + rect.bottom) / 2 > 150) this.flip = '-10%';
      if ((rect.top + rect.bottom) / 2 > 200) this.flip = '-20%';
      if ((rect.top + rect.bottom) / 2 > 250) this.flip = '-30%';
      if ((rect.top + rect.bottom) / 2 > 350) this.flip = '-40%';
      if ((rect.top + rect.bottom) / 2 > 400) this.flip = '-50%';
      if ((rect.top + rect.bottom) / 2 > 450) this.flip = '-60%';
      if ((rect.top + rect.bottom) / 2 > 500) this.flip = '-70%';
      if ((rect.top + rect.bottom) / 2 > 550) this.flip = '-80%';
      if ((rect.top + rect.bottom) / 2 > 600) this.flip = '-90%';
      if ((rect.top + rect.bottom) / 2 > 650) this.flip = '-95%';
    },
    tryClose() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped vars="{X, Y, flip}">
.tooltip {
  position: relative;
  text-align: justify;
}

.tooltip span {
  display: none;
  overflow: hidden;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  color: black;
  background: white;
}

.tooltip span img {
  float: left;
  margin: 0px 8px 8px 0;
}

.tooltip:hover span,
.tooltip:active span {
  display: block;
  position: fixed;
  top: var(--Y);
  left: var(--X);
  z-index: 1000;
  width: 430px;
  min-height: 300px;
  max-height: 600px;
  border: 1px solid black;
  padding: 12px;
  transform: translate(-101%, var(--flip));
}
</style>
