export default {
  setIsLayerLoading(state, payload) {
    state.isLayerLoading = payload;
  },
  setTheMap(state, payload) {
    state.theMap = payload;
  },
  setTheMapAndClean(state, payload) {
    state.theMap = payload;
  },
  async setMapForms(state, payload) {
    state.mapForms = await payload;
  },
  async setMapFormsTypes(state, payload) {
    state.mapFormsTypes = await payload;
  },
  changeCurrentType(state, payload) {
    state.mapFormCurrentType = payload;
  },
  setMapDetailsIdList(state, payload) {
    state.mapDetailsIdList = payload;
  },
  setMapDetails(state, payload) {
    state.mapDetails = payload;
  },
  setMapDetailsRiskFactor(state, payload) {
    state.mapDetailsRiskFactor = payload;
  },
  setMapDetailsRiskFactorTemplate(state, payload) {
    state.mapDetailsRiskFactorTemplate = payload;
  },
  setFormsDetails(state, payload) {
    state.mapFormDetails = payload;
  },
  setRiskFactorList(state, payload) {
    state.RiskFactorList = payload;
  },
  setRiskFactorExtraDataList(state, payload) {
    state.RiskFactorExtraDataList = payload;
  },
  setRiskFactorListIds(state, payload) {
    state.RiskFactorListIds = payload;
  },
  setTutorialStepper(state, payload) {
    state.tutorialStepper = payload;
  },
  setFormTooltipData(state, payload) {
    state.formTooltipData = payload;
  },
  setHistoricList(state, payload) {
    state.historicFilesList = payload;
  },
  setTooltipImgUrls(state, payload) {
    state.tooltipImgUrlsList = payload;
  }
};
