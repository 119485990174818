import {createStore} from 'vuex';

import mapModule from './map/index.js'
import authModule from './auth/index.js'

const store = createStore({
    modules: {
      map: mapModule,
      auth: authModule
    }
  });
  
  export default store;