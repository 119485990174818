<template>
    <button v-if="!link" :class="mode">
        <slot></slot>
    </button>
    <router-link v-else :to="to" :class="mode">
        <slot></slot>
    </router-link>
</template>

<script>
export default {
    props: {
        mode:{
            type: String,
            required:false,
            default: null
        },
        link:{
            type:Boolean,
            required:false,
            default:false
        },
        to: {
            type:String,
            required:false,
            defalut:'/'
        }
    }
}
</script>

<style scoped>
button,
a {
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  font: inherit;
  background-color: #ff5c00;
  border: 1px solid #ff5c00;
  color: white;
  cursor: pointer;
  border-radius: 10px;

  display: inline-block;
}

a:hover,
a:active,
button:hover,
button:active {
  background-color: #dd5100;
  border-color: #dd5100;
}

.flat {
  background-color: transparent;
  color:#ff5c00;
  border: none;
}

.outline {
  background-color: #ffeee8;
  border-color: #ff5c00;
  color:#ff5c00;
}

.flat:hover,
.flat:active,
.outline:hover,
.outline:active {
  background-color: #ffcbb6;
}
</style>