<template>
  <div>
    <base-dialog :show="!!error" title="Atencion!" @close="handleError">
      <p>{{ error }}</p>
    </base-dialog>
    <base-backdrop :show="isLoading" title="Autentificando..." fixed>
      <base-spinner></base-spinner>
    </base-backdrop>
    <form @submit.prevent="submitForm">
      <div
        v-bind:style="{ 'margin-bottom': 15 - formStepper * 3 + 'px' }"
        class="buttonWrap"
      >
        <img
          v-bind:style="disableBackArrow"
          :src="image"
          alt="Atras"
          width="40"
          height="40"
          @click="formStepperDown()"
        />
        <img
          v-bind:style="disableForwardArrow"
          :src="image"
          alt="adelante"
          width="40"
          height="40"
          @click="formStepperUp()"
        />
        <base-button class="btn" @click="tryClose">Ingresar Datos</base-button>
      </div>
      <div
        class="formWrap"
        v-for="(titleArray, titleArrayIndex) in getFormBody"
        :key="titleArray"
      >
        <div v-show="titleArrayIndex === formStepper">
          <div v-for="(title, titleId) in titleArray" :key="title">
            <div class="titleCounter">
              <h3>{{ titleId }}</h3>
              <h3 id="formStepper">
                {{ formStepper + 1 }}/{{ getFormBody.length }}
              </h3>
            </div>
            <div v-for="subtitleArray in title" :key="subtitleArray">
              <div v-for="subtitle in subtitleArray" :key="subtitle">
                <div v-for="attribute in subtitle" :key="attribute">
                  <p>{{ attribute }}</p>
                  <span>
                    <formTooltip :title="attribute" class="formTooltip" />
                    <select v-model="datos[attribute]" required>
                      <option
                        v-for="details in getFormDetails[attribute]"
                        :key="details"
                        :value="extractDetailsValue(details)"
                      >
                        {{ extractDetailsName(details) }}
                      </option>
                    </select>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div style="border-top: 1px solid black"></div>
          <!--this adds the bottom border, vue has a bug with v-show and borders-->
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import formTooltip from '../layout/formTooltip.vue';
export default {
  components: {
    formTooltip
  },
  data() {
    return {
      datos: {},
      isLoading: false,
      error: null,
      formStepper: 0,
      image: require('../../assets/img/caret-left.svg'),
      tooltipImg: require('../../assets/img/question-square.svg'),
      isFormActive: false,
      selectedAttribute: undefined
    };
  },
  async created() {},
  mounted() {
    this.datos = {};
  },
  emits: ['close'],
  computed: {
    getFormBody() {
      return this.$store.getters['getFormBody'];
    },
    getFormDetails() {
      return this.$store.getters['getFormDetails'];
    },
    buttonWrapMargin() {
      const x = 10 - this.formStepper;
      console.log(x);
      return { marginBottom: x + 'px' };
    },
    disableBackArrow() {
      if (this.formStepper === 0)
        return {
          filter: 'grayscale(100%)',
          position: 'relative',
          float: 'left',
          margin: '6px'
        };
      else
        return {
          filter: 'grayscale(0)',
          position: 'relative',
          float: 'left',
          margin: '6px'
        };
    },
    disableForwardArrow() {
      if (this.formStepper === this.getFormBody.length - 1)
        return {
          filter: 'grayscale(100%)',
          transform: 'scaleX(-1)',
          position: 'relative',
          float: 'left',
          margin: '6px'
        };
      else
        return {
          filter: 'grayscale(0)',
          transform: 'scaleX(-1)',
          position: 'relative',
          float: 'left',
          margin: '6px'
        };
    }
  },
  methods: {
    formStepperUp() {
      if (this.formStepper < this.getFormBody.length - 1) {
        this.formStepper++;
      }
    },
    formStepperDown() {
      if (this.formStepper > 0) {
        this.formStepper--;
      }
    },
    tryClose() {
      this.$emit('close');
    },
    handleError() {
      this.error = null;
    },
    setSelectedAttribute(attribute) {
      this.selectedAttribute = attribute;
      this.isFormActive = true;
    },
    deselect() {
      this.selectedAttribute = undefined;
      this.isFormActive = false;
    },
    async submitForm() {
      try {
        this.isLoading = true;
        await this.$store.dispatch('submitFormData', this.datos);
        this.isLoading = false;
        this.error = 'La información fue recibida con exito!';
      } catch (err) {
        this.error = err.message;
      }
    },
    extractDetailsName(object) {
      let name = '';
      for (var key in object) {
        name = key;
      }
      return name;
    },
    extractDetailsValue(object) {
      let value = '';
      for (var key in object) {
        value = object[key];
      }
      return value;
    }
  }
};
</script>
<style scoped>
div {
  overflow: auto;
}
select {
  width: 200px;
  border: none;
  border-left: solid 1px black;
}
option {
  position: relative;
  max-width: 90%;
  margin: 0px 0px 0px 0px;
  text-overflow: ellipsis;
}
form {
  position: relative;
  margin: 0px 0px 0px 0px;
  overflow: hidden;
}
.titleCounter {
  background-color: rgb(240, 240, 240);
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}
.buttonWrap {
  position: relative;
  border: 1px solid black;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.35);
  margin-bottom: 15px;
  border-radius: 5px;
  background-color: rgb(240, 240, 240);
  overflow: hidden;
  width: 400px;
  left: 100px;
  text-align: center;
  vertical-align: middle;
  align-content: center;
}
form .formWrap {
  margin: 3px 3px 3px 3px;
  border-left: 1px solid black;
  border-right: 1px solid black;
  background-color: rgb(240, 240, 240);
}
form div div div div div {
  overflow: hidden;
  margin: 10px 5px 10px 5px;
}
form div div div div div div {
  display: grid;
  border: 0;
  background-color: rgb(240, 240, 240);
  padding: 1px 0px 1px 8px;
  border: 1px solid black;
  margin: 5px 5px 5px 5px;
}
form div div div div div div p {
  grid-column: 1;
  position: relative;
  width: 330px;
  padding: 2px 0 0 0;
  margin: 0;
  font-size: 13px;
}
h3 {
  margin-left: 15px;
  display: inline-block;
  max-width: 500px;
}
span {
  grid-column-start: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}
span .formTooltip {
  margin-right: 10px;
}
#formStepper {
  position: relative;
  float: right;
  right: 20px;
}
h4 {
  font-weight: normal;
  font-style: italic;
  margin-left: 14px;
}
.btn {
  position: relative;
  float: right;
  margin: 6px;
}
.btn:disabled {
  background-color: #d3d3d3;
}
.tooltip:hover {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0;
}
</style>
