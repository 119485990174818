export default {
    setUserList(state, payload) {
        state.userList = payload;
    },
    setCurrentPage(state, payload) {
        state.currentPage = payload;
    },
    setCurrentUserEmail(state, payload) {
        state.userEmail = payload
    },
    setUserReadOnly(state, payload) {
        state.userReadOnly = payload
    }
}