<template>
  <header>
    <img :src="logo" alt="Logout" width="120" height="120" id="logo" />
    <nav>
      <router-link to="/home" class="noStyle">
        <div>
          <div><h2>PORTAL GESTIÓN DEL RIESGO DE DESASTRE</h2></div>
          <div><h4>Sitio Patrimonio Mundial de Valparaíso</h4></div>
        </div>
      </router-link>
      <ul>
        <li v-if="currentPage" @click="unfocusDetails()">
          <b>AMENAZA</b>
          <select
            v-model="currentType"
            :disabled="isLayerLoading"
            class="select-css"
            @change="changeType()"
          >
            <option
              v-for="(type, index) in getMapFormsTypes"
              :key="type"
              :value="index"
            >
              {{ type.toUpperCase() }}
            </option>
          </select>
        </li>
        <li class="userEmail">
          <b>{{ getCurrentUserEmail }}</b>
        </li>
        <li v-if="currentPage" @click="unfocusDetails()">
          <router-link to="/map" class="noStyle">
            <img
              title="Mapa"
              class="mapIcon"
              :src="mapIcon"
              alt="Ir al mapa"
              width="30"
              height="30"
            />
          </router-link>
          <router-link to="/table" class="noStyle">
            <img
              title="Tabla"
              class="tableIcon"
              :src="tableIcon"
              alt="Ir a la Tabla"
              width="30"
              height="30"
            />
          </router-link>
          <img
            title="Tutorial"
            class="questionIcon"
            :src="questionIcon"
            alt="Ir al mapa"
            width="30"
            height="30"
            @click="isFormActive = !isFormActive"
          />
          <img
            title="Desconectar"
            class="logoutIcon"
            :src="logoutIcon"
            alt="Logout"
            width="30"
            height="30"
            @click="logout()"
          />
        </li>
        <li v-if="getCurrentPage == 'signup' || getCurrentPage == 'home'">
          <img
            title="Desconectar"
            class="logoutIcon"
            :src="logoutIcon"
            alt="Logout"
            width="30"
            height="30"
            @click="logout()"
          />
        </li>
      </ul>
    </nav>
    <base-modal :show="isFormActive" @close="closeForm" :title="currentStepper">
      <tutorial-body type="header" />
    </base-modal>
  </header>
</template>

<script>
export default {
  data() {
    return {
      currentType: 0,
      logo: require('../../assets/img/version-colores-corporativos-caluga-municipalidad.svg'),
      logoutIcon: require('../../assets/img/power.svg'),
      mapIcon: require('../../assets/img/map.svg'),
      tableIcon: require('../../assets/img/table.svg'),
      questionIcon: require('../../assets/img/question-circle.svg'),

      isFormActive: false,
      titles: [
        'VISUALIZACIÓN',
        'VISUALIZACIÓN',
        'EVALUACIÓN POR INMUEBLE',
        'EVALUACIÓN POR INMUEBLE'
      ]
    };
  },
  created() {},
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$store.commit('changeCurrentType', 0);
      this.$store.commit('setMapFormsTypes', []);
      this.currentType = 0;
    },
    changeType() {
      this.$store.commit('setRiskFactorExtraDataList', []);
      this.$store.commit('setRiskFactorList', []);
      this.$store.commit('changeCurrentType', this.currentType);
    },
    unfocusDetails() {
      if (this.getCurrentPage == 'map') {
        this.$store.commit('setMapDetails', [[]]);
        this.$store.commit('setMapDetailsIdList', []);
        this.$store.commit('setMapDetailsRiskFactor', null);
      }
    },
    closeForm() {
      this.$store.commit('setTutorialStepper', 0);
      this.isFormActive = false;
    }
  },
  computed: {
    isLayerLoading() {
      return this.$store.getters['getIsLayerLoading'];
    },
    getMapFormsTypes() {
      const result = this.$store.getters['getMapFormsTypes'];
      if (result.length === 0) return ['Cargando...'];
      else {
        this.$store.dispatch('LoadFirstRisk');
        this.$store.dispatch('loadMapRiskList');
        return result;
      }
    },
    getCurrentPage() {
      return this.$store.getters['currentPage'];
    },
    getCurrentUserEmail() {
      return this.$store.getters['currentUserEmail'];
    },
    currentPage() {
      if (this.getCurrentPage == 'map' || this.getCurrentPage == 'table') {
        return true;
      } else return false;
    },
    currentStepper() {
      return this.titles[this.$store.getters['getTutorialStepper']];
    }
  }
};
</script>

<style scoped>
header {
  position: relative;
  width: 100%;
  height: 5rem;
  background-color: #006bca;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.26);
}

header a {
  text-decoration: none;
  color: #ff5e00;
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border: 1px solid transparent;
}

#logo {
  position: relative;
  margin: 0;
  padding: 0;
  top: 0px;
  right: -5px;
  pointer-events: none;
}

h2 {
  margin: 0;
  color: white;
  width: 500px;
  font-size: 18px;
}
h4 {
  margin: 0;
  color: white;
  width: 450px;
  font-size: 14px;
}

h2 a {
  color: white;
  margin: 0;
}

h2 a:hover,
h2 a:active,
h2 a.router-link-active {
  border-color: transparent;
}

header nav {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

li {
  margin: 0 1rem;
}
li b {
  color: white;
  font-size: 14px;
}
li .userEmail {
  display: inline-block;
  word-break: break-all;
  color: white;
  font-size: 14px;
  max-width: 300px;
}

.select-css {
  display: block;
  font-size: 14px;
  font-weight: 700;
  color: black;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.5em;
  width: 139px; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: 0.5px solid #aaa;
  border-radius: 1px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
    for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
    
  */
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 0.65em auto, 100%;
}
/* Hide arrow icon in IE browsers */
.select-css::-ms-expand {
  display: none;
}
/* Hover style */
.select-css:hover {
  border-color: #888;
}
/* Focus style */
/* .select-css:focus {
  border-color: #ff5c00;
  box-shadow: 0 0 1px 1px  #ff5c00;
  box-shadow: 0 0 0 1px -moz-mac-focusring;
  color: #222; 
  outline: none;
} */

/* Set options to normal weight */
.select-css option {
  font-weight: normal;
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir='rtl'] .select-css,
:root:lang(ar) .select-css,
:root:lang(iw) .select-css {
  background-position: left 0.7em top 50%, 0 0;
  padding: 0.6em 0.8em 0.5em 1.4em;
}

/* Disabled styles */
.select-css:disabled,
.select-css[aria-disabled='true'] {
  color: graytext;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
}

.select-css:disabled:hover,
.select-css[aria-disabled='true'] {
  border-color: #aaa;
}
select:focus {
  border-color: none;
  outline: none;
}
.mapIcon {
  filter: invert(1);
}
.tableIcon {
  filter: invert(1);
  margin-left: 20px;
}
.questionIcon {
  filter: invert(1);
  cursor: pointer;
  margin-left: 20px;
}
.logoutIcon {
  filter: invert(1);
  margin-left: 35px;
  cursor: pointer;
}
.noStyle {
  padding: 0;
  margin: 0;
}
@media only screen and (max-width: 1200px) {
  .userEmail {
    display: none;
  }
  h2 {
    margin: 0;
    color: white;
    width: 400px;
    font-size: 16px;
  }
  h4 {
    margin: 0;
    color: white;
    width: 370px;
    font-size: 14px;
  }
  .select-css {
    font-size: 11px;
  }
}
@media only screen and (max-width: 820px) {
  h2 {
    display: none;
  }
  h4 {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  #logo {
    display: none;
  }
}
</style>
