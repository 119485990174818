<template>
  <div class="sidebar">
    <h3>Información</h3>
    <h4>Activar Capa</h4>
    <a><layer-loader name="Límite SPM" :dbName="LayerNames[0]"/></a>
    <!-- <a v-if="getFormType === 'deslizamiento de tierra'"><layer-loader name="Inclinación" :dbName="LayerNames[1]"/></a> -->
    <!-- <hr class="rounded" /> -->
    <img :src="cameraIcon" width="30" alt="" class="cameraIcon" />
    <export-map-button class="exportbtn" />
    <div class="leyend">
      <!-- <hr class="rounded" /> -->
      <map-leyend />
    </div>
  </div>
</template>

<script>
import mapLeyend from './mapLeyend.vue';
import exportMapButton from './exportMap.vue';
import layerLoader from './layerLoader.vue';
export default {
  components: {
    mapLeyend,
    exportMapButton,
    layerLoader
  },
  data() {
    return {
      cameraIcon: require('../../assets/img/camera-fill.svg'),
      LayerNames: ['zonaUnesco', 'Inclinacion']
    };
  },
  computed: {
    getFormType() {
      return this.$store.getters['getFormType'];
    }
  }
};
</script>

<style scoped>
.sidebar {

  height: 100%;
  width: 200px;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  background-color: rgb(233, 233, 233);
  overflow-x: hidden;
  padding: 1rem;
  padding-top: 100px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}
.sidebar h3 {
  color: black;
  padding: 0;
  margin: 0px 0px 10px 0px;
}
.sidebar h4 {
  color: black;
}
/* Style sidebar links */
.sidebar a {
  padding: 8px 8px 2px 8px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
}

img {
  margin: 1.5rem 0 0.75rem 0;
}

/* Style links on mouse-over */
.sidebar a:hover {
  color: #f1f1f1;
}

/* Style the main content */
.main {
  margin-left: 160px; /* Same as the width of the sidenav */
  padding: 0px 10px;
}

/* Add media queries for small screens (when the height of the screen is less than 450px, add a smaller padding and font-size) */
@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;
  }
  .sidebar a {
    font-size: 18px;
  }
}

.leyend {
  pointer-events: none;
  color: azure;
  position: absolute;
  margin-left: 0px;
  bottom: 40px;
}
.exportbtn {
  pointer-events: all;
  margin-bottom: 15px;
}
hr.rounded {
  border-top: 4px solid lightgray;
  border-radius: 5px;
  margin: 10px 12px 13px 12px;
}
</style>
