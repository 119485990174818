<template>
  <div class="container">
    <div class="tab" :disabled="isLayerLoading" @change="changeType()">
      <button
        class="tablinks"
        v-for="(type, index) in getMapFormsTypes"
        :class="{ active: selectedTable === type }"
        :key="index"
        :disable="isLoading"
        @Click="componentLoading(type)"
      >
        {{ capString(type) }}
      </button>
    </div>
    <div v-if="error">
      <h1>{{ error }}</h1>
    </div>
    <div v-else class="grid-list">
      <table
        v-for="(files, name) in historicFilesList"
        :key="name"
        id="user-list"
      >
        <template v-if="selectedTable === name">
          <tr>
            <th>Nombre</th>
            <th>Fecha</th>
            <th class="download">Descargar</th>
          </tr>
          <tr v-for="user in files" :key="user.name" v-show="!isTableLoading">
            <td>{{ user.name }}</td>
            <td>{{ dateInSpanish(user.name) }}</td>
            <td class="download2" @click="downloadFile(user.path)">
              <img :src="image" alt="Descargar" />
            </td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
export default {
  data() {
    return {
      selectedTable: '',
      isLoading: true,
      isDownloading: false,
      error: null,
      image: require('../../assets/img/download.svg')
    };
  },
  methods: {
    componentLoading(type) {
      if (this.isLoading === false) {
        this.selectedTable = type;
        this.$forceUpdate();
      }
    },
    dateInSpanish(name) {
      const date = _.split(name, '-', 2);
      const año = _.split(date[1], '.', 1);
      let mes = '';
      if (date[0] === '1') mes = 'Enero';
      if (date[0] === '2') mes = 'Febrero';
      if (date[0] === '3') mes = 'Marzo';
      if (date[0] === '4') mes = 'Abril';
      if (date[0] === '5') mes = 'Mayo';
      if (date[0] === '6') mes = 'Junio';
      if (date[0] === '7') mes = 'Julio';
      if (date[0] === '8') mes = 'Agosto';
      if (date[0] === '9') mes = 'Septiembre';
      if (date[0] === '10') mes = 'Octubre';
      if (date[0] === '11') mes = 'Noviembre';
      if (date[0] === '12') mes = 'Diciembre';
      if (mes === '') return date[1];
      else return mes + ' del ' + año[0];
    },
    capString(word) {
      if (word) return _.capitalize(word);
      else return;
    },
    async downloadFile(path) {
      this.isDownloading = true;
      try {
        await this.$store.dispatch('DownloadFile', path);
        this.isDownloading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async getHistoricLists() {
      this.isLoading = true;
      try {
        await this.$store.dispatch('queryHistoricFilesList');
        this.isLoading = false;
      } catch (err) {
        this.error = 'No se logro acceder a los archivos historicos';
        this.isLoading = false;
      }
    }
  },
  computed: {
    getMapFormsTypes() {
      const result = this.$store.getters['getMapFormsTypes'];
      if (result.length === 0) return ['Cargando...'];
      else {
        this.getHistoricLists(result);
        return result;
      }
    },
    historicFilesList() {
      return this.$store.getters['getHistoricFilesList'];
    }
  }
};
</script>

<style scoped>
/* Style the tab */
.tab {
  overflow: hidden;
  border-bottom: 1px solid #ccc;
  background-color: #f1f1f1;
  border-radius: 5px 5px 0 0;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: rgb(194, 194, 194);
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: rgb(182, 182, 182);
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
.grid-list {
  margin: 1rem auto;
  height: 330px;
  width: 95%;
  align-self: center;
  font-size: 20px;
  text-align: center;
  overflow-y: auto;
}
#user-list {
  position: relative;
  font-size: 14px;
  border-collapse: collapse;
  width: 100%;
  scroll-behavior: auto;
}
#user-list td,
#user-list th {
  border: 1px solid #ddd;
  padding: 8px;
}
#user-list tr:nth-child(even) {
  background-color: #f2f2f2;
}

#user-list tr:hover {
  background-color: #ddd;
}

#user-list th {
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #006bca;
  color: white;
}
#user-list img {
  vertical-align: middle;
}
.download,
.download2 {
  width: 250px;
  padding: 100px;
}
#user-list .download2:hover {
  background-color: rgb(182, 182, 182);
}
</style>
