<template>
  <table id="mapDetails">
    <tr>
      <td id="attribute">ROL_SII</td>
      <td>{{ getDetails[1] }}</td>
    </tr>
    <tr>
      <td id="attribute">DIRECCION</td>
      <td>{{ getDetails[2] }}</td>
    </tr>
    <tr>
      <th colspan="2">{{ getMapFormType.toUpperCase() }}</th>
    </tr>
  </table>
</template>

<script>
export default {
  data() {
    return {
      riskFactors: []
    };
  },
  computed: {
    getDetailsId() {
      return this.$store.getters['getMapDetailsId'];
    },
    getDetails() {
      return this.$store.getters['getMapDetails'][0];
    },
    getDetailsRiskFactor() {
      return this.$store.getters['getMapDetailsRiskFactor'];
    },
    getMapFormType() {
      return this.$store.getters['getFormType'];
    }
  }
};
</script>

<style scoped>
#mapDetails {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#mapDetails td,
#mapDetails th {
  font-size: 13px;
  border: 1px solid #ddd;
  background-color: rgb(240, 240, 240);
  padding: 8px;
  border: 1px solid rgb(175, 175, 175);
}

#mapDetails tr:nth-child(even) {
  background-color: #f2f2f2;
}

#mapDetails tr:hover {
  background-color: #ddd;
}

#mapDetails th {
  font-size: 14px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #006bca;
  color: white;
}
#attribute {
  text-align: left;
}
</style>
